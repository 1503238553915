const ML_BASE_API_ROUTE = '/ml';
const ML_REPORT_GENERATION_API_ROUTE = '/ml/report';

const ML_REPORT_GENERATION_API_ROUTES = {
  index: () => ML_REPORT_GENERATION_API_ROUTE,
  config: () => `${ML_REPORT_GENERATION_API_ROUTE}/config`,
  init: () => `${ML_REPORT_GENERATION_API_ROUTE}/init`,
  generate: () => `${ML_REPORT_GENERATION_API_ROUTE}/generate`,
  reportInfo: (reportId: string) =>
    `${ML_REPORT_GENERATION_API_ROUTE}/${reportId}`,
} as const;

export const ML_API_ROUTES = {
  index: () => ML_BASE_API_ROUTE,
  tableExtraction: () => `${ML_BASE_API_ROUTE}/table-extraction`,
  askNeedl: () => `${ML_BASE_API_ROUTE}/ask-needl`,
  askNeedlFeedback: () => `${ML_BASE_API_ROUTE}/ask-needl/feedback`,
  askNeedlMessaging: () => `${ML_BASE_API_ROUTE}/ask-needl/messaging`,
  reportGeneration: ML_REPORT_GENERATION_API_ROUTES,
} as const;
