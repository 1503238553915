// @ts-strict-ignore
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTypewriter } from 'react-simple-typewriter';

import { trackEvent } from '@/analytics/trackEvent';
import { Tooltip } from '@/components/Atoms/Tooltip/Tooltip';
import { ConfigUI } from '@/components/Feature';
import { IconButton } from '@/components/IconButton/IconButton';
import {
  type ClearButtonType,
  ClearButton,
} from '@/features/ChatBotV2/components/ClearButton';
import { Input } from '@/features/ChatBotV2/components/Input';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';
import { useHeaderText } from '@/features/Feed/hooks/useHeaderText';
import { useFeatureConfigUI } from '@/hooks/query/useFeatureConfig';
import { useLocationMatch } from '@/hooks/useLocationMatch';
import { getLogo } from '@/utils/getIcons';

import { ProToggle } from './ProToggle';
import { ScrollToBottomButton } from './ScrollToBottom';

const FooterImpl = ({
  listContainerRef,
  scrollContainerRef,
  isLoading,
  onSubmit,
  handleClear,
  hasMessages,
  stopGenerating,
  rows,
  isStreaming = false,
  pro,
}: FooterImplType) => {
  const [showShadow, setShowShadow] = React.useState(false);
  const { feature: featureEnabled } = useFeatureConfigUI('ask_needl_pro');

  const formRef = React.useRef<HTMLFormElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleTextareResize = React.useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, []);

  const {
    params: { subCategory },
  } = useRouteMatch<{
    subCategory: string;
  }>(['/:category/:source/:subCategory']) || {
    params: {},
  };
  const { plainText } = useHeaderText();
  const { askNeedlPageMatch } = useLocationMatch();

  // TODO : confirm & clean this up later
  React.useEffect(() => {
    const listContainer = listContainerRef?.current;

    const resizeObserver = new ResizeObserver(() => {
      if (
        listContainer &&
        listContainer.scrollHeight > listContainer.clientHeight
      ) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    });

    const scrollHandler = () => {
      if (
        listContainer &&
        listContainer.scrollHeight <=
          Math.round(listContainer.scrollTop + listContainer.clientHeight)
      ) {
        setShowShadow(false);
      } else {
        setShowShadow(true);
      }
    };

    const mutaionObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          for (const node of mutation.addedNodes) {
            resizeObserver.observe(node as Element);
          }
          for (const node of mutation.removedNodes) {
            resizeObserver.unobserve(node as Element);
          }
        }
      }
    });

    if (listContainer) {
      mutaionObserver.observe(listContainer, { childList: true });
      for (const child of listContainer.children) {
        resizeObserver.observe(child);
      }
      listContainer.addEventListener('scroll', scrollHandler, {
        passive: true,
      });
    }

    return () => {
      resizeObserver.disconnect();
      mutaionObserver.disconnect();
      listContainer?.removeEventListener('scroll', scrollHandler);
    };
  }, [listContainerRef]);

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    formRef.current?.reset();
    textareaRef.current?.focus();
    handleTextareResize();
  }, [isLoading, handleTextareResize]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    const formData = new FormData(form);
    const prompt = formData.get('botPrompt');

    if (!prompt) {
      return;
    }
    if (typeof prompt !== 'string') {
      return;
    }

    if (textareaRef.current) {
      textareaRef.current.value = '';
    }

    onSubmit(prompt);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      formRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
      return;
    }
  };

  const handleInput = () => {
    handleTextareResize();
  };

  const clearButtonProps: ClearButtonType = {
    onClick: () => {
      handleClear();
      if (textareaRef.current) {
        textareaRef.current.value = '';
        textareaRef.current.focus();
      }
    },
    disabled: !hasMessages || isLoading,
  };

  const handleStop = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    formRef.current?.reset();
    stopGenerating();
    trackEvent('ask_needl_stop_generating', {
      source: askNeedlPageMatch ? 'Full Screen' : 'Copilot',
      tabName: subCategory,
      feedName: plainText,
    });
  };

  const SendIcon = getLogo('ask_needl_send_icon');
  const StarsIcon = getLogo('ask_needl_stars');
  const StopIcon = getLogo('ask_needl_stop_logo');

  const placeholderWords = React.useMemo(() => {
    return getPlaceholderMessages(hasMessages, featureEnabled);
  }, [hasMessages, featureEnabled]);

  const placeholderMessage = useTypewriter({
    words: placeholderWords,
    ...TYPEWRITER_CONFIG,
  })[0];

  return (
    <footer
      className={`px-3 py-4 flex-shrink-0 flex flex-col gap-2 transition-shadow duration-300 ease-out relative ${
        showShadow ? 'shadow-bot-input' : ''
      }`.trim()}
    >
      <div className='absolute w-10 h-10 z-20 left-1/2 -top-10'>
        <ScrollToBottomButton scrollRef={scrollContainerRef} />
      </div>
      <div className='flex-1 flex items-start gap-2'>
        <div className='h-9 flex items-center'>
          <ClearButton {...clearButtonProps} />
        </div>
        <form className='flex-1' ref={formRef} onSubmit={handleSubmit}>
          <Input
            ref={React.useCallback((ref) => {
              if (ref) {
                ref.focus();
              }
              textareaRef.current = ref;
            }, [])}
            className={`${pro ? 'border !border-emerald-900' : ''}`}
            disabled={isLoading}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            placeholder={placeholderMessage}
            name='botPrompt'
            required
            label='Promt Needl bot'
            id='bot-promt'
            rows={rows}
            startAdorment={
              <div className='pl-2'>
                <StarsIcon
                  className={`w-4 h-4 ${
                    pro ? 'fill-emerald-900' : 'fill-gray-400'
                  }`}
                />
              </div>
            }
            endAdornment={
              <>
                <ConfigUI feature='ask_needl_pro'>
                  <ProToggle />
                </ConfigUI>
                {isStreaming ? (
                  <Tooltip label='Stop Generating'>
                    <IconButton size='small' onClick={handleStop}>
                      <StopIcon
                        className={pro ? 'fill-emerald-900' : 'fill-gray-700'}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton type='submit' size='small' disabled={isLoading}>
                    <SendIcon
                      className={`${
                        isLoading
                          ? pro
                            ? 'text-emerald-800'
                            : 'text-gray-400'
                          : pro
                          ? 'text-emerald-900'
                          : 'text-gray-700'
                      }`}
                    />
                  </IconButton>
                )}
              </>
            }
          />
        </form>
      </div>
    </footer>
  );
};

export const Footer = withAskNeedlStateSlice<FooterType, FooterImplType>(
  FooterImpl,
  (props, state) => {
    return {
      ...props,
      pro: state.data.pro,
    };
  }
);

const TYPEWRITER_CONFIG = {
  typeSpeed: 25,
  deleteSpeed: 20,
  loop: Number.POSITIVE_INFINITY,
  delaySpeed: 2000,
};

const getPlaceholderMessages = (hasMessages: boolean, proEnabled: boolean) => {
  return [
    hasMessages ? FOLLOW_UP_PLACEHOLDER : INITIAL_PLACEHOLDER,
    ...(proEnabled ? [PRO_PLACEHOLDER] : []),
    HELP_PLACEHOLDER,
  ];
};

const INITIAL_PLACEHOLDER = 'Ask me anything...';
const FOLLOW_UP_PLACEHOLDER = 'Ask follow up...';
const HELP_PLACEHOLDER = 'Add @help to your question if you need help';
const PRO_PLACEHOLDER =
  'Enable Pro for complex queries needing nuanced understanding or comprehensive answers.';

type FooterType = {
  isLoading?: boolean;
  onSubmit: (prompt: string) => void;
  hasMessages: boolean;
  handleClear: () => void;
  listContainerRef?: React.RefObject<HTMLDivElement>;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  rows?: number;
  isStreaming?: boolean;
  stopGenerating: () => void;
};

type FooterImplType = FooterType & {
  pro: boolean;
};
