import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { trackEvent } from '@/analytics/trackEvent';
import { axiosInstance } from '@/axios';
import { AppOverlay } from '@/components/AppOverlay';
import { forceRefreshTokens, signOut } from '@/components/AuthProvider';
import { ErrorBoundary as ErrorBoundaryImpl } from '@/components/ErrorBoundaries/ErrorBoundary';
import { ErrorBoundaryWithReset } from '@/components/ErrorBoundaries/ErrorBoundaryWithReset';
import { LoginRedirectDialog } from '@/components/legacy_Components/Dialog/LoginRedirectDialog/LoginRedirectDialog';
import { LazyLoadingFallback } from '@/components/Loaders/LazyLoadingFallback';
import { OverlayWidthProvider } from '@/components/Preview/hooks/useOverlayWidthDetails';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { TrackRouteChange } from '@/components/TrackRouteChange';
import { NavContextProvider } from '@/context/NavContext';
import { AskNeedlButton } from '@/features/ChatBotV2/components/AskNeedlButton';
import {
  AskNeedlContextProvider,
  getInitialState,
} from '@/features/ChatBotV2/context';
import { PricingContextProvider } from '@/features/Pricing/context';
import { useFetchUserPreferences } from '@/hooks/query/useFetchUserPreferences';
import { useBackNavigation } from '@/hooks/useBackNavigation';
import {
  AUTH_ROUTES,
  DOCUMENT_ROUTE,
  EXTENSION_ROUTE,
  IMAGE_ROUTE,
  NEEDL_PULSE_ROUTES,
  OAUTH_ROUTES,
  ONBOARDING_ROUTES,
  REDIRECT_BASE_ROUTE,
} from '@/nav-routes';
import { useNavigate } from '@/router';
import { nativeEvent } from '@/utils/native-event';
import {
  DownloadToastError,
  DownloadToastSuccess,
  NativeDownloadSuccessToast,
} from '@/utils/toasts';
const cloudProvider = import.meta.env.VITE_CLOUD_PROVIDER;
const Home = React.lazy(() => import('./index/index'));
export const OAuthCallbackHandlerV2 = React.lazy(
  () => import('./OAuthCallbackV2')
);
const Onboarding = React.lazy(() => import('./Onboarding'));
const ImageViewer = React.lazy(() => import('./ImageViewer'));
const PDFViewer = React.lazy(() => import('./PDFViewer'));
const Extension = React.lazy(() => import('./Extension'));
const AuthRoutesV2 = React.lazy(() => import('./AuthRoutesV2'));
const NeedlPulseRoutes = React.lazy(() => import('@/features/NeedlPulse'));
const Redirect = React.lazy(() => import('./Redirect'));

const AppStateWrapper: React.FunctionComponent = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const homeFeedMatch = useRouteMatch('/feeds/home');
  const firstRender = React.useRef(true);

  const goBack = useBackNavigation();

  const isHomeFeed = !!homeFeedMatch;

  const openConfirmationDialog = React.useCallback(() => {
    setOpen(true);
  }, []);

  React.useEffect(() => {
    if (isHomeFeed && firstRender.current) {
      trackEvent('everything_feed', { homeFeed: true });
      firstRender.current = false;
    }
  }, [isHomeFeed]);

  // listen events from react-native webview to determine the platform
  React.useEffect(() => {
    function handleEvent(message: MessageEvent) {
      if (message.data.device) {
        window.RN_PLATFORM = message.data.device;
        return;
      }

      if (message.data.status.includes('path:')) {
        const path = message.data.status.split(':')[1];
        navigate.push(path);
        return;
      }

      if (message.data.status === 'download-completed-album') {
        DownloadToastSuccess();
        return;
      }

      if (message.data.status === 'download-completed-file') {
        NativeDownloadSuccessToast();
        return;
      }

      // listen back event from react-native webview
      if (message.data.status === 'history-back') {
        goBack();
        return;
      }

      if (message.data.status === 'download-error') {
        DownloadToastError();
        return;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener('message', handleEvent as any);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      document.removeEventListener('message', handleEvent as any);
    };
  }, [goBack, navigate]);

  React.useEffect(() => {
    axiosInstance.interceptors.response.use(
      (res) => res,
      async (error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 403
        ) {
          await signOut();
          window.localStorage.removeItem('accessToken');
          openConfirmationDialog();
        }
        return Promise.reject(error);
      }
    );

    let interval: NodeJS.Timeout | null;

    const refreshSession = async () => {
      const refresh = async () => {
        await forceRefreshTokens();
      };

      const refreshInterval =
        cloudProvider === 'aws' ? 4 * 60 * 1000 : 30 * 60 * 1000;

      if (document.visibilityState === 'visible') {
        await refresh();

        interval = setInterval(async () => {
          await refresh();
        }, refreshInterval);

        nativeEvent(['auth', JSON.stringify(true)]);
      } else {
        if (interval) {
          clearInterval(interval);
          interval = null;
        }
      }
    };

    window.addEventListener('visibilitychange', refreshSession);

    return () => {
      window.removeEventListener('visibilitychange', refreshSession);
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };
  }, [navigate, openConfirmationDialog]);

  const handleOk = () => {
    setOpen(false);
    navigate.push('/');
  };

  return (
    <>
      {children}
      <LoginRedirectDialog open={open} handleOk={handleOk} />
      <TrackRouteChange />
    </>
  );
};

const ContextProviders: React.FunctionComponent = ({ children }) => {
  const { isLoading, data: userPreferences } = useFetchUserPreferences();

  if (isLoading) {
    return <LazyLoadingFallback />;
  }

  return (
    <NavContextProvider initialState={userPreferences?.navState}>
      <PricingContextProvider>
        <AskNeedlContextProvider
          initialState={getInitialState({
            pro: userPreferences?.askNeedlPro,
          })}
        >
          <OverlayWidthProvider>{children}</OverlayWidthProvider>
        </AskNeedlContextProvider>
      </PricingContextProvider>
    </NavContextProvider>
  );
};

const RouteWithProvider = () => {
  return (
    <ContextProviders>
      <Switch>
        <ProtectedRoute path={IMAGE_ROUTE}>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryWithReset}
            onReset={() => history.go(-1)}
          >
            <React.Suspense fallback={<LazyLoadingFallback />}>
              <ImageViewer />
            </React.Suspense>
          </ErrorBoundary>
        </ProtectedRoute>
        <ProtectedRoute path='/'>
          <ErrorBoundary FallbackComponent={ErrorBoundaryImpl}>
            <React.Suspense fallback={<LazyLoadingFallback />}>
              <Home />
            </React.Suspense>
          </ErrorBoundary>
        </ProtectedRoute>
      </Switch>
      <AppOverlay />
      <AskNeedlButton />
    </ContextProviders>
  );
};

const App = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <AppStateWrapper>
      <Switch>
        {/* Needl Pulse Routes */}
        <Route path={NEEDL_PULSE_ROUTES.BASE_ROUTE}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryImpl}>
            <React.Suspense fallback={<LazyLoadingFallback />}>
              <NeedlPulseRoutes />
            </React.Suspense>
          </ErrorBoundary>
        </Route>

        {/* Needl Routes */}
        <Route path={[OAUTH_ROUTES.REDIRECT, AUTH_ROUTES.BASE_ROUTE]}>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryWithReset}
            onReset={() => history.go(-1)}
          >
            <React.Suspense fallback={<LazyLoadingFallback />}>
              <AuthRoutesV2 />
            </React.Suspense>
          </ErrorBoundary>
        </Route>
        <Route path={DOCUMENT_ROUTE}>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryWithReset}
            onReset={() => history.go(-1)}
          >
            <React.Suspense fallback={<LazyLoadingFallback />}>
              <PDFViewer />
            </React.Suspense>
          </ErrorBoundary>
        </Route>
        <ProtectedRoute path={REDIRECT_BASE_ROUTE}>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryWithReset}
            onReset={() => history.go(-1)}
          >
            <React.Suspense fallback={<LazyLoadingFallback />}>
              <Redirect />
            </React.Suspense>
          </ErrorBoundary>
        </ProtectedRoute>
        <ProtectedRoute path={ONBOARDING_ROUTES.BASE_ROUTE}>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryWithReset}
            onReset={navigate.goBack}
          >
            <React.Suspense fallback={<LazyLoadingFallback />}>
              <Onboarding />
            </React.Suspense>
          </ErrorBoundary>
        </ProtectedRoute>
        <ProtectedRoute path={EXTENSION_ROUTE}>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryWithReset}
            onReset={navigate.goBack}
          >
            <React.Suspense fallback={<LazyLoadingFallback />}>
              <Extension />
            </React.Suspense>
          </ErrorBoundary>
        </ProtectedRoute>
        <ProtectedRoute path='*'>
          <RouteWithProvider />
        </ProtectedRoute>
      </Switch>
    </AppStateWrapper>
  );
};

export default App;
