import { css } from 'styled-components';
import tw from 'twin.macro';

export type CommonTypes = {
  autoComplete?: string;
  label?: React.ReactNode;
  labelHidden?: boolean;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: string;
};

export const BaseInputStyle = css`
  ${tw`w-full body-sm rounded-lg border border-gray-300 px-4 py-2.5 focus:shadow-none focus:outline-none transition-colors duration-150 ease-in-out`}
`;

export const NoErrorInputStyle = css`
  ${tw`text-gray-900 placeholder-gray-500 focus:ring-gray-900 focus:border-gray-900 focus:placeholder-gray-500`}
`;

export const LabelHiddenStyle = css`
  ${tw`sr-only`}
`;

export const LabelStyle = css`
  ${tw`block body-sm-bold font-medium mb-1`}
`;
