import { useMutation } from 'react-query';

import { USER_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import type { NavState } from '@/context/NavContext';
import { useRefetchUserPreferences } from '@/hooks/query/useFetchUserPreferences';

export const useUpdateUserPreference = () => {
  const invalidateUserPreferences = useRefetchUserPreferences();
  return useMutation(
    ({ nav_state, askneedl_pro }: RequestPayloadType) =>
      axiosInstance.patch(USER_API_ROUTES.preferences(), {
        nav_state,
        askneedl_pro,
      }),
    {
      onSettled: async () => {
        await invalidateUserPreferences();
      },
    }
  );
};

type RequestPayloadType = {
  nav_state?: NavState;
  askneedl_pro?: boolean;
};
