import type { Moment, unitOfTime } from 'moment';
import moment from 'moment';

import type { DateState, Nullable } from '@/types';

type ShorthandToMomentReturnType = {
  startDate: Moment;
  endDate: Moment;
};

type DateFormattedType = {
  startDate: string;
  endDate: string;
  durationOption: string;
};

/**
 * Util to conver Date Shorthands (1d, 1W, 1M, 1Y) to Moment instance
 */
const shorthandToMoment = (shorthand: string): ShorthandToMomentReturnType => {
  if (!shorthand.match(/\w{2}/g)) throw new Error('Wrong shorthand');

  const [, amount, unit] = /(\w)(\w)/g.exec(shorthand) ?? [];

  return {
    endDate: moment(),
    startDate: moment().subtract(
      amount,
      unit as unitOfTime.DurationConstructor
    ),
  };
};

const dateFilterFormat = (date: DateState) => {
  let dateRange: Nullable<DateFormattedType> = null;

  if (date.endDate && date.startDate) {
    dateRange = {
      startDate: date.startDate.format('DD-MM-YYYY'),
      endDate: date.endDate.format('DD-MM-YYYY'),
      durationOption: date.durationOption,
    };
  }

  return dateRange;
};

const DATE_OPTIONS = [
  { key: 'Last 1 day', value: '1d' },
  { key: 'Last 1 week', value: '1w' },
  { key: 'Last 1 month', value: '1M' },
  { key: 'Last 3 months', value: '3M' },
  { key: 'Last 6 months', value: '6M' },
  { key: 'Last 1 year', value: '1y' },
  { key: 'Last 2 years', value: '2y' },
  { key: 'Last 3 years', value: '3y' },
];

const getCurrentDateString = () => {
  const now = new Date();
  const day = now.getDate();
  const suffix = ['th', 'st', 'nd', 'rd'][
    day % 10 > 3 || [11, 12, 13].includes(day % 100) ? 0 : day % 10
  ];
  const month = now.toLocaleString('default', { month: 'short' });
  const year = now.getFullYear();

  return `${day}${suffix} ${month} ${year}`;
};

export {
  DATE_OPTIONS,
  shorthandToMoment,
  dateFilterFormat,
  getCurrentDateString,
};
