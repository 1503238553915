import type { CustomCheckboxContainerProps } from '@reach/checkbox';
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox';
import type * as React from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

type CommonCheckProps = {
  /**
   * controlled check state
   */
  checked?: boolean;
  /**
   * uncontrolled initial check state
   */
  defaultChecked?: boolean;
  /**
   * checkbox disabled
   */
  disabled?: boolean;
  /**
   * onChange handler
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * onBlur handler
   */
  onBlur?: () => void;
  /**
   * onFocus handler
   */
  onFocus?: () => void;
  /**
   * padding around the input
   */
  padding?: boolean;
};

type StyledCheckboxType = CustomCheckboxContainerProps &
  Pick<CommonCheckProps, 'padding'>;

const commonCheckboxContainerStyles = css<StyledCheckboxType>`
  ${tw`mr-0! cursor-pointer flex-shrink-0 focus:border-none! focus:outline-none! focus-visible:outline-none transition-colors`}
  ${({ padding }) =>
    padding ? tw`w-8 h-8 p-1.5 rounded-full` : tw`w-5 h-5 rounded`}
    ${({ padding, disabled }) => padding && !disabled && tw`hover:bg-gray-300`}
    ${({ disabled }) => disabled && tw`opacity-50 cursor-not-allowed!`}
  & svg path {
    ${tw`transition-colors ease-out duration-500`}
  }
  &[data-state='unchecked'] {
    --fill-color: ${({ disabled }) =>
      disabled ? 'var(--gray-500)' : 'var(--gray-700)'};
    & svg path {
      ${tw`fill-current text-[var(--fill-color)]`}
    }
  }
`;

const StyledCheckbox = styled(CustomCheckboxContainer)<StyledCheckboxType>`
  &[data-reach-custom-checkbox-container] {
    ${commonCheckboxContainerStyles}
    &[data-focused] {
      ${({ disabled }) => !disabled && tw`outline-none ring-1 ring-emerald-900`}
      ${({ padding, disabled }) =>
        !disabled && padding && tw`ring-offset-2 bg-gray-300`}
    }
  }
`;

const StyledCheckboxInput = styled(CustomCheckboxInput)`
  outline: none;
`;

export type { CommonCheckProps };

export { StyledCheckbox, StyledCheckboxInput, commonCheckboxContainerStyles };
