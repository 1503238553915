import styled from 'styled-components';
import tw from 'twin.macro';

import { Tooltip } from '@/atoms/Tooltip/Tooltip';
import { Button } from '@/components/Button/Button';
import { IconButton } from '@/components/IconButton/IconButton';
import FilterIcon from '@/icons/filter.svg?react';
import MoveIcon from '@/icons/move.svg?react';
import DeleteIcon from '@/icons/trash.svg?react';

type TableHeaderIconButtonImplType = Parameters<typeof IconButton>[0];
type TableHeaderIconButtonType = Omit<
  Parameters<typeof IconButton>[0],
  'children'
>;

type PrimaryButtonType = Parameters<typeof Button>[0];

const StyledDeleteIcon = styled(DeleteIcon)<{ disabled: boolean }>`
  ${tw`fill-current text-gray-700`}
  ${({ disabled }) => disabled && tw`text-gray-300`}
`;

const StyledFilterIcon = styled(FilterIcon)`
  ${tw`fill-current text-gray-700`}
`;

const StyledMoveIcon = styled(MoveIcon)<{ disabled: boolean }>`
  ${tw`fill-current text-gray-700`}
  ${({ disabled }) => disabled && tw`text-gray-300`}
`;

const StyledButton = styled(Button)`
  ${tw`ml-4 capitalize`}
`;

export const Divider = styled.div`
  ${tw`w-px h-4 bg-gray-400`}
`;

const TableHeaderIconButton = ({
  size = 'small',
  label,
  ...rest
}: TableHeaderIconButtonImplType) => {
  return (
    <Tooltip label={label}>
      <IconButton size={size} aria-label={label} {...rest} />
    </Tooltip>
  );
};

export const DeleteButton = (props: TableHeaderIconButtonType) => {
  return (
    <TableHeaderIconButton label={props.label || 'Delete'} {...props}>
      <StyledDeleteIcon disabled={props.disabled || false} />
    </TableHeaderIconButton>
  );
};

export const FilterButton = (props: TableHeaderIconButtonType) => {
  return (
    <TableHeaderIconButton label='Filters' {...props}>
      <StyledFilterIcon />
    </TableHeaderIconButton>
  );
};

export const MoveButton = (props: TableHeaderIconButtonType) => {
  return (
    <TableHeaderIconButton label='Move' {...props}>
      <StyledMoveIcon disabled={props.disabled || false} />
    </TableHeaderIconButton>
  );
};

export const PrimaryButton = ({
  variant = 'secondary',
  size = 'medium',
  ...rest
}: PrimaryButtonType) => {
  return <StyledButton variant={variant} size={size} {...rest} />;
};
