// @ts-strict-ignore
import * as React from 'react';
import ReactCopyToClipboard from 'react-copy-to-clipboard';
import Lottie from 'react-lottie';

import { Divide } from '@/components/Atoms/Divide';
import { Icon } from '@/components/Atoms/Icon';
import { Tooltip } from '@/components/Atoms/Tooltip/Tooltip';
import {
  BotError as BotErrorImpl,
  BotStreamedMessage,
} from '@/components/ChatBot/components/Message/BotMessage/BotStreamedMessage';
import {
  type AskNeedlSentenceType,
  useScrollIntoView,
} from '@/components/ChatBot/hooks';
import { useBotMessageHook } from '@/components/ChatBot/hooks/useBotMessageHook';
import { IconButton } from '@/components/IconButton/IconButton';
import { DEFAULT_LOTTIE_OPTIONS } from '@/constants/defaultLottieOptions';
import { Citation } from '@/features/ChatBotV2/components/Citation';
import { MessageWrapper } from '@/features/ChatBotV2/components/MessageWrapper';
import type { AskNeedlResponseType } from '@/features/ChatBotV2/hooks/useAskNeedl';
import CopyIcon from '@/icons/copy.svg?react';
import LikeIcon from '@/icons/thumbs-up.svg?react';
import { formatLinkString } from '@/utils/formatLinkString';
import { getLogo } from '@/utils/getIcons';

import { RichMessage } from './RichMessage';

export const BotLoader = () => {
  return <AIMessageContainer prompt={null} animate />;
};

export const BotError = () => {
  return <AIMessageContainer prompt={<BotErrorImpl />} />;
};

export const BotMessage = React.memo(
  ({
    streamingAnswer,
    data,
    formattedData,
    prompt,
    answerMarkdown,
    generatingStopped = false,
  }: BotMessageType) => {
    if (streamingAnswer) {
      return (
        <AIMessageContainer
          animate={!generatingStopped}
          prompt={<BotStreamedMessage streamingAnswer={streamingAnswer} />}
        />
      );
    }

    return (
      <AIMessageContainer
        prompt={
          <MessageGroup
            answerMarkdown={answerMarkdown}
            formattedData={formattedData}
            prompt={prompt}
            sentences={
              data?.generated_answer?.sentences as AskNeedlSentenceType[]
            }
            messageId={data?.message_id as string}
            answerQuality={data?.answer_quality}
            pro={data.pro}
          />
        }
      />
    );
  }
);

const MessageGroup = ({
  answerMarkdown,
  formattedData,
  prompt,
  sentences,
  messageId,
  answerQuality,
  pro,
}: MessageGroupType) => {
  return (
    <div className='flex flex-col gap-2' ref={useScrollIntoView()}>
      {answerMarkdown ? (
        <RichMessage markdown={answerMarkdown} className='space-y-2' />
      ) : (
        <p className='text-sm text-gray-900 whitespace-pre-wrap'>
          {sentences?.map((sentence) => (
            <React.Fragment key={sentence.sentence}>
              {formatLinkString(sentence.sentence, 'normal')}
              {sentence.citations?.length > 0
                ? sentence.citations.map((citation) => (
                    <Citation key={citation.id} {...citation} />
                  ))
                : null}
            </React.Fragment>
          ))}
        </p>
      )}
      <AnswerQualityMessaging answerQuality={answerQuality} />
      <MessageFooter
        formattedData={formattedData}
        messageId={messageId}
        prompt={prompt}
        pro={pro}
      />
    </div>
  );
};

const AIMessageContainer = ({
  prompt,
  animate = false,
}: {
  prompt: React.ReactNode;
  animate?: boolean;
}) => {
  const [animationData, setAnimationData] = React.useState(null);
  const AskNeedlStarGradient = getLogo('ask_needl_star_gradient');

  const loadAnimationData = async () => {
    try {
      const data = await import('./animationData.json');
      setAnimationData(data.default || data);
    } catch (error) {
      console.error('Error loading animation data:', error);
    }
  };

  // Run the async function inside useEffect
  React.useEffect(() => {
    if (animate) {
      loadAnimationData();
    }
  }, [animate]);

  return (
    <MessageWrapper
      avatar={
        animate && animationData ? (
          <Lottie
            options={{ ...DEFAULT_LOTTIE_OPTIONS, animationData }}
            height={25}
            width={25}
          />
        ) : (
          <Icon size='medium'>
            <AskNeedlStarGradient />
          </Icon>
        )
      }
      prompt={prompt}
      variant='bot'
    />
  );
};

const MessageFooter = ({
  formattedData,
  messageId,
  prompt,
  pro,
}: Pick<
  MessageGroupType,
  'formattedData' | 'messageId' | 'pro' | 'prompt'
>) => {
  const {
    handleSubmit,
    handleCopy,
    selectedFeedback,
    setSelectedFeedback,
    feedbackMutation,
  } = useBotMessageHook({ messageId, prompt });

  return (
    <div className='flex items-center justify-between print:hidden'>
      <div className='flex gap-2'>
        <Tooltip label='Copy'>
          <div>
            <ReactCopyToClipboard
              text={formattedData ?? ''}
              onCopy={handleCopy}
            >
              <IconButton size='small'>
                <CopyIcon className='text-emerald-900' />
              </IconButton>
            </ReactCopyToClipboard>
          </div>
        </Tooltip>
        <form onSubmit={handleSubmit}>
          <div className='flex gap-2'>
            <IconButton
              name='feedback'
              value='CORRECT'
              type='submit'
              size='small'
              disabled={feedbackMutation.isLoading}
              onClick={() => setSelectedFeedback('correct')}
            >
              <LikeIcon
                className={`stroke-emerald-900 ${
                  selectedFeedback === 'correct'
                    ? 'text-emerald-500'
                    : 'text-transparent'
                }`.trim()}
              />
            </IconButton>
            <IconButton
              name='feedback'
              value='INCORRECT'
              type='submit'
              size='small'
              disabled={feedbackMutation.isLoading}
              onClick={() => setSelectedFeedback('incorrect')}
            >
              <LikeIcon
                className={`rotate-180 stroke-emerald-900 ${
                  selectedFeedback === 'incorrect'
                    ? 'text-emerald-500'
                    : 'text-transparent'
                }`.trim()}
              />
            </IconButton>
          </div>
        </form>
      </div>
      {pro ? (
        <span className='body-xs text-secondary-green-900 px-2 py-1 rounded border border-green-500 bg-green-300'>
          PRO
        </span>
      ) : null}
    </div>
  );
};

const AnswerQualityMessaging = ({
  answerQuality,
}: AnswerQualityMessagingType) => {
  if (answerQuality !== 'LOW') {
    return null;
  }

  return (
    <div>
      <Divide className='my-4' />
      <div className='flex gap-3 items-center'>
        <div className='flex gap-2 items-center bg-gray-300 font-semibold px-3 py-1 rounded-md'>
          <p className='text-white bg-red-400 rounded-full text-xs w-4 h-4 text-center'>
            L
          </p>
          <p className=''>Low</p>
        </div>
        <p className='text-gray-700'>
          We found that the answer may not be satisfactory. Please verify the
          information or rephrase your question.
        </p>
      </div>
    </div>
  );
};

type BotMessageType = {
  answerMarkdown?: string;
  generatingStopped?: boolean;
  pro?: boolean;
  streamingAnswer: string;
  data: AskNeedlResponseType | undefined;
  formattedData: string | null;
  prompt: string;
};

type MessageGroupType = {
  answerMarkdown?: string;
  formattedData: string | null;
  prompt: string;
  sentences: AskNeedlSentenceType[];
  messageId: string;
  answerQuality?: 'HIGH' | 'LOW';
  pro: boolean;
};

type AnswerQualityMessagingType = {
  answerQuality?: 'HIGH' | 'LOW';
};
