import type * as React from 'react';

import { VerticalDivide } from '@/atoms/Divide';
import type { PhillColors } from '@/atoms/Pill/Pill';
import { Pill } from '@/atoms/Pill/Pill';
import { Tooltip } from '@/atoms/Tooltip/Tooltip';

type TagTypes = 'shared_tags' | 'user_tags';

type TagProps = {
  className?: string;
  dark?: boolean;
  tagKey?: string;
  value?: string;
  color?: PhillColors;
  source?: string;
  type?: TagTypes;
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  truncate?: boolean;
};

export type TagRenderProp = (props: TagElementType) => React.ReactNode;

export type TagElementType = Omit<TagProps, 'truncate'>;

export type TagsType = {
  tags: TagElementType[];
  children?: TagRenderProp;
  tagsCount?: number;
};

export const Tag = ({
  className,
  color,
  dark,
  tagKey,
  value,
  icon,
  onClick,
  truncate = true,
}: TagProps) => {
  return (
    <Tooltip key={value} label={`${tagKey ? `${tagKey}: ` : ''}${value}`}>
      <Pill
        onClick={onClick}
        color={color}
        dark={dark}
        className={`${className} flex items-center ${
          onClick ? 'cursor-pointer' : ''
        } ${truncate ? 'pill-width' : ''}`}
      >
        {tagKey ? <span className='body-xs-bold'>{tagKey}:&nbsp;</span> : null}
        <span className={`body-xs ${truncate ? 'truncate' : ''}`}>{value}</span>
        {icon ? (
          <span className='flex-shrink-0 w-4 h-4 fill-current text-gray-900 ml-1'>
            {icon}
          </span>
        ) : null}
      </Pill>
    </Tooltip>
  );
};

export const Tags = ({ tags, tagsCount, children }: TagsType) => {
  const displayedTags = tags.slice(0, tagsCount);
  const sharedTags = displayedTags.filter(({ type }) => type === 'shared_tags');

  const userTags = displayedTags.filter(({ type }) => type === 'user_tags');

  const systemTags = displayedTags.filter(({ type }) => type === undefined);

  return (
    <>
      {[sharedTags, userTags].map((tags, index) => (
        <>
          {tags.length > 0 ? (
            <>
              <DisplayTags tags={tags}>{children}</DisplayTags>
              {(index === 0 && userTags.length > 0) ||
              (index === 1 && systemTags.length > 0) ? (
                <VerticalDivide className='h-4 my-auto' />
              ) : null}
            </>
          ) : null}
        </>
      ))}

      {systemTags.length > 0 ? (
        <DisplayTags tags={systemTags}>{children}</DisplayTags>
      ) : null}
    </>
  );
};

const DisplayTags = ({ tags, children }: TagsType) => {
  return (
    <>
      {tags.map(
        ({
          tagKey,
          value,
          color = 'success',
          onClick,
          source,
          type,
          dark,
          icon,
        }) =>
          typeof children === 'function' ? (
            children({
              color,
              tagKey,
              onClick,
              value,
              source,
              type,
              dark,
              icon,
            })
          ) : (
            <Tag
              dark={dark}
              key={value}
              color={color}
              tagKey={tagKey}
              value={value}
              onClick={onClick}
              icon={icon}
            />
          )
      )}
    </>
  );
};
