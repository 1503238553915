import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { USER_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import type { NavState } from '@/context/NavContext';

const QUERY_KEY = ['user_preferences'];

export const useFetchUserPreferences = () => {
  return useQuery(
    QUERY_KEY,
    async ({ signal }: QueryFunctionContext) => {
      return axiosInstance.get<PreferenceResponseType>(
        USER_API_ROUTES.preferences(),
        { signal }
      );
    },
    {
      select: (data) => {
        return {
          navState: data.data.user_preferences.nav_state,
          askNeedlPro: data.data.user_preferences.askneedl_pro,
        };
      },
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useRefetchUserPreferences = () => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEY);
  }, [queryClient]);
};

type PreferenceResponseType = {
  user_preferences: {
    nav_state: NavState;
    askneedl_pro: boolean;
  };
};
