const USER_BASE_ROUTE = '/user';

export const USER_API_ROUTES = {
  baseRoute: () => USER_BASE_ROUTE,
  delete: () => `${USER_BASE_ROUTE}/delete`,
  userVerification: () => `${USER_BASE_ROUTE}/verification`,
  storage: () => `${USER_BASE_ROUTE}/storage`,
  preferences: () => `${USER_BASE_ROUTE}/preferences`,
  savedEmailsLists: () => `${USER_BASE_ROUTE}/saved-emails-lists`,
} as const;
