import * as React from 'react';

import type { SearchWithinType } from '@/features/ChatBotV2/context';
import { useChatPanelLogic } from '@/features/ChatBotV2/hooks/useChatPanelLogic';
import type { SessionState } from '@/features/ChatBotV2/types';

export const useChatPanelCommonProps = ({
  currentFeedOrDocumentId,
  searchWithin,
  sessionState,
  pro,
}: useChatPanelPropsType) => {
  const listContainerRef = React.useRef<HTMLDivElement>(null);

  const {
    isLoading,
    isStreaming,
    isError,
    handleAskQuestion,
    handleClear,
    saveToNoteHandler,
    snipNoteMutation,
    stopGenerating,
  } = useChatPanelLogic({
    currentFeedOrDocumentId,
    searchWithin,
    sessionState,
    pro,
  });

  return {
    isLoading,
    isStreaming,
    isError,
    handleAskQuestion,
    handleClear,
    saveToNoteHandler,
    snipNoteMutation,
    listContainerRef,
    stopGenerating,
  };
};

export type useChatPanelPropsType = {
  currentFeedOrDocumentId: string;
  searchWithin: SearchWithinType;
  sessionState: SessionState;
  pro: boolean;
};
