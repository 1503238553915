import * as React from 'react';

import { Citation as CitationImpl } from '@/components/Atoms/Citation';
import { Popper } from '@/components/Popper/Popper';
import type { AskNeedlCitationType } from '@/features/ChatBotV2/hooks/useAskNeedl';

export const CitationBase = ({
  href,
  id,
  content_only,
  title_label,
  source_label,
  highlight_indexes,
  unix,
}: CitationBaseType) => {
  const anchorRef = React.useRef<HTMLParagraphElement>(null);
  const popperRef = React.useRef<HTMLDivElement>(null);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = React.useState(false);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setHovered(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      if (document.activeElement !== parentRef.current) {
        setHovered(false);
      }
    }, 200);
  };

  const handleTouchToggle = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setHovered((prevHovered) => !prevHovered);
  };

  const contentToShow = React.useMemo(() => {
    if (highlight_indexes.length === 0) {
      return content_only;
    }

    if (!content_only) {
      return '';
    }

    return highlight_indexes
      .map(({ start, end }) => {
        const words = content_only.split(/\s+/);
        const charCount = words.reduce(
          (acc, word, index) => {
            const wordLength = word.length + 1;
            acc.totalChars += wordLength;

            if (acc.startWordIndex === -1 && acc.totalChars > start) {
              acc.startWordIndex = Math.max(index - 5, 0);
            }
            if (acc.totalChars >= end) {
              acc.endWordIndex = Math.min(index + 6, words.length);
              acc.completed = true;
            }

            return acc;
          },
          {
            totalChars: 0,
            startWordIndex: -1,
            endWordIndex: -1,
            completed: false,
          }
        );

        const beforeHighlight = words
          .slice(charCount.startWordIndex, charCount.startWordIndex + 5)
          .join(' ');
        const highlightText = content_only.slice(start, end);
        const afterHighlight = words
          .slice(charCount.endWordIndex - 6, charCount.endWordIndex)
          .join(' ');

        return `${beforeHighlight} ${highlightText} ${afterHighlight}..... `;
      })
      .join('');
  }, [content_only, highlight_indexes]);

  const highlightedTexts = React.useMemo(
    () =>
      highlight_indexes.map(({ start, end }) => content_only.slice(start, end)),
    [highlight_indexes, content_only]
  );

  const date = React.useMemo(() => {
    if (!unix) {
      return '';
    }
    const date = new Date(parseInt(unix) * 1000);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }, [unix]);

  return (
    <div
      className='inline-flex'
      ref={parentRef}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <p
        ref={anchorRef}
        className='bg-emerald-900 hover:opacity-80 text-white ml-1 relative bottom-0.5 flex justify-center items-center w-4 h-4 text-[10px] font-bold rounded-sm border cursor-pointer'
        onTouchStart={handleTouchToggle}
      >
        {id}
      </p>
      <Popper
        isVisible={hovered}
        ref={popperRef}
        targetRef={anchorRef}
        placement='bottom-start'
        closeOnOutSideClick
        closePopper={handleMouseLeave}
        showAnimation={false}
      >
        <CitationImpl
          firstHeading={title_label}
          secondHeading={source_label?.replace(/\b\w/g, (c) => c.toUpperCase())}
          highlightText={highlightedTexts}
          text={contentToShow}
          date={date}
          link={href}
        />
      </Popper>
    </div>
  );
};

type CitationBaseType = Pick<
  AskNeedlCitationType,
  | 'content_only'
  | 'context'
  | 'highlight_indexes'
  | 'id'
  | 'source_label'
  | 'title_label'
  | 'unix'
> & {
  href: string;
};
