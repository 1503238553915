import clamp from 'lodash.clamp';

import { Text } from '@/atoms/Text/Text';

export type StatusTextType = {
  count: number;
  page: number;
  rowCount: number;
};

export const StatusText = ({
  count,
  page,
  rowCount,
}: StatusTextType): JSX.Element => {
  return (
    <Text as='span' id='pagination-status'>
      <strong>{page * rowCount - rowCount + 1}</strong> to{' '}
      <strong>{clamp(page * rowCount, count)}</strong> of{' '}
      <strong>{count}</strong> results
    </Text>
  );
};
