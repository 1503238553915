import * as React from 'react';
import { useReactToPrint } from 'react-to-print';

export const usePrintDOMNode = (title: string) => {
  const domNodeRef = React.useRef<HTMLDivElement | null>(null);

  const exportToPDF = useReactToPrint({
    content: () => domNodeRef.current,
    documentTitle: title,
    bodyClass: 'my-8 mx-12',
  });

  return {
    domNodeRef,
    printHandler: exportToPDF,
  };
};
