import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import type {
  CellProps,
  TableToggleAllRowsSelectedProps,
  TableToggleRowsSelectedProps,
} from 'react-table';
import styled from 'styled-components';
import tw from 'twin.macro';

import { CheckBox, Radio } from '@/atoms/Check';
import { IconContainer } from '@/atoms/IconContainer/IconContainer';
import { Text } from '@/atoms/Text/Text';
import { Tooltip } from '@/atoms/Tooltip/Tooltip';
import ExpandIcon from '@/icons/expand_right.svg?react';
import type { CommonTextdecorationType } from '@/types';
import { bytesToSize } from '@/utils/bytesToSize';
import { getFormattedCount } from '@/utils/getFormattedCount';

import type { SortArrowType } from './common';
import { SortArrow } from './SortArrow';

type SortHeaderType<T extends Record<string, unknown>> = SortArrowType<T> & {
  children: React.ReactNode;
  rightAlign?: boolean;
};

type CellType<T extends Record<string, unknown> = Record<string, unknown>> =
  React.PropsWithChildren<CellProps<T>>;
type BytesWithLinkType = CommonTextdecorationType;
type ImageContainerType = { alignCenter?: boolean; clickable?: boolean };

const FlexRowContainer = styled.div`
  ${tw`flex items-center space-x-4`}

  & > :is(svg, img) {
    ${tw`w-6 h-6 flex-shrink-0`}
  }
`;

const FlexColContainer = styled.div`
  ${tw`flex flex-col`}

  & > :is(svg, img) {
    ${tw`w-6 h-6 flex-shrink-0`}
  }
`;

const ImageContainer = styled.div<ImageContainerType>`
  ${tw`w-14 h-14 rounded bg-gray-300 overflow-hidden flex-shrink-0`}
  ${({ alignCenter }) => alignCenter && tw`grid place-content-center`}
  ${({ clickable = true }) => (clickable ? tw`cursor-pointer` : null)}

  & img {
    ${tw`w-full h-full`}
  }

  & svg {
    ${tw`w-6 h-6`}
  }
`;

const IconWrapper = styled.div`
  ${tw`w-4 h-4 text-gray-700 fill-current`}
`;

const CellTextImpl = React.forwardRef<HTMLElement, Record<string, unknown>>(
  ({ bold, capitalize, children, ...rest }, ref) => (
    <Text
      as='span'
      bold={!!bold || undefined}
      className={`truncate ${capitalize ? 'capitalize' : ''}`}
      ref={ref}
      {...rest}
    >
      {children ?? '-'}
    </Text>
  )
);

const CellText: React.FunctionComponent<Record<string, unknown>> = (props) => {
  const { tooltip, children } = props;
  if (tooltip) {
    return (
      <Tooltip label={children}>
        <CellTextImpl {...props} />
      </Tooltip>
    );
  }
  return <CellTextImpl {...props} />;
};

const CheckboxCell = (
  props: {
    disabled?: boolean;
  } & (TableToggleAllRowsSelectedProps | TableToggleRowsSelectedProps)
): JSX.Element => {
  const { indeterminate, disabled = false, ...rest } = props;

  return (
    <CheckBox
      size='medium'
      disabled={disabled}
      intermediate={indeterminate}
      {...rest}
    />
  );
};

const RadioCell = (
  props: {
    disabled?: boolean;
  } & (TableToggleAllRowsSelectedProps | TableToggleRowsSelectedProps)
): JSX.Element => {
  const { disabled = false, ...rest } = props;

  return <Radio disabled={disabled} {...rest} />;
};

const ByteFormattedCell: React.FunctionComponent<CommonTextdecorationType> = ({
  children,
  color = 'gray',
  bold = true,
  size = 'sm',
  dark = false,
}) => {
  if (Number(children) === -1) {
    return <Skeleton />;
  }
  return (
    <Text
      color={color}
      bold={bold}
      size={size}
      dark={dark}
      className='text-right'
    >
      {bytesToSize(Number(children))}
    </Text>
  );
};

const ByteFormattedWithIcon: React.FunctionComponent<BytesWithLinkType> = ({
  children,
  ...rest
}) => {
  if (Number(children) === -1) {
    return <Skeleton />;
  }
  return (
    <div className='flex items-center'>
      <div className='flex-grow' />
      <ByteFormattedCell {...rest}>{children}</ByteFormattedCell>
      <IconContainer className='w-6 h-6'>
        <ExpandIcon />
      </IconContainer>
    </div>
  );
};

const NumberFormattedCell: React.FunctionComponent<
  CommonTextdecorationType
> = ({ children, ...rest }) => {
  if (Number(children) === -1) {
    return <Skeleton />;
  }
  if (!children) {
    return <Text {...rest}>-</Text>;
  }

  return <Text {...rest}>{getFormattedCount(Number(children))}</Text>;
};

const SortHeader = <T extends Record<string, unknown>>({
  children,
  column,
  rightAlign,
}: SortHeaderType<T>): JSX.Element => {
  return (
    <div className='flex items-center space-x-2'>
      {rightAlign ? <div className='flex-grow' /> : null}
      <span>{children}</span>
      <SortArrow column={column} />
    </div>
  );
};

export type { CellType };
export {
  FlexRowContainer,
  FlexColContainer,
  ImageContainer,
  IconWrapper,
  CellText,
  CheckboxCell,
  ByteFormattedCell,
  ByteFormattedWithIcon,
  NumberFormattedCell,
  RadioCell,
  SortHeader,
};
