import { useFeatureConfigUI } from '@/hooks/query/useFeatureConfig';
import { useLocationMatch } from '@/hooks/useLocationMatch';
import { useQueryParams } from '@/hooks/useQueryParams';

export const useShowAskNeedl = () => {
  const { feature, isLoading: isFeatureConfigLoading } =
    useFeatureConfigUI('ask_needl_copilot');
  const { notebooksMatch, sourcePreviewMatch } = useLocationMatch();
  const [_, queryParams] = useQueryParams();

  const isNotebooksOverlay =
    queryParams['overlay-source'] === 'needl_notebooks';
  const { category } = sourcePreviewMatch?.params ?? {};

  return (
    !notebooksMatch &&
    !isNotebooksOverlay &&
    category !== 'needl_notebooks' &&
    !isFeatureConfigLoading &&
    feature
  );
};
