// @ts-strict-ignore
import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import type { CommonCheckProps } from '@/atoms/Check/common';
import {
  commonCheckboxContainerStyles,
  StyledCheckbox,
  StyledCheckboxInput,
} from '@/atoms/Check/common';
import { useControlled } from '@/hooks/useControlled';

import CheckedIcon from './icons/checked.svg?react';
import UncheckIcon from './icons/uncheck.svg?react';

const StyledDisplayRadioContainer = styled.span<
  Pick<CommonCheckProps, 'disabled' | 'padding'>
>`
  ${tw`cursor-pointer`}
  &[data-reach-custom-checkbox-container] {
    ${commonCheckboxContainerStyles}
  }
`;

export const Radio = React.forwardRef<HTMLInputElement, CommonCheckProps>(
  ({ checked, defaultChecked, disabled, onChange, padding, ...rest }, ref) => {
    const [value, setValue, isControlled] = useControlled<boolean>({
      controlled: checked,
      default: !!defaultChecked,
      name: 'Checkbox',
    });

    const checkedValue = isControlled ? checked : value;
    const changeHandler = isControlled
      ? onChange
      : (event: React.ChangeEvent<HTMLInputElement>) =>
          setValue(event.target.checked);

    const icon = checkedValue ? <CheckedIcon /> : <UncheckIcon />;

    return (
      <StyledCheckbox
        ref={ref}
        checked={checkedValue}
        disabled={disabled}
        onChange={changeHandler}
        padding={padding}
        {...rest}
      >
        <StyledCheckboxInput type='radio' />
        {icon}
      </StyledCheckbox>
    );
  }
);

export const DisplayRadio = React.forwardRef<
  HTMLInputElement,
  CommonCheckProps
>(({ checked, disabled, padding }, ref) => {
  const icon = checked ? <CheckedIcon /> : <UncheckIcon />;

  return (
    <StyledDisplayRadioContainer
      ref={ref}
      data-reach-custom-checkbox-container
      disabled={disabled}
      padding={padding}
      data-state={checked ? 'checked' : 'unchecked'}
      role='checkbox'
      aria-checked={checked}
    >
      {icon}
    </StyledDisplayRadioContainer>
  );
});
