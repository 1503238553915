// @ts-strict-ignore
import * as React from 'react';

import {
  type ReportGeneratorActions,
  type ReportGeneratorState,
  useReportGeneratorState,
} from '@/features/ReportGeneration/hooks';

export const ReportGeneratorContext =
  React.createContext<ReportGeneratorContextType | null>(null);

export const ReportGeneratorDispatchContext =
  React.createContext<React.Dispatch<ReportGeneratorActions> | null>(null);

export const useReportGeneratorContext = (): ReportGeneratorContextType => {
  const value = React.useContext(ReportGeneratorContext);

  if (!value) {
    throw new Error(
      'useReportGeneratorContext should be used inside ReportGeneratorContextProvider'
    );
  }

  return value;
};

export const useReportGeneratorContextDispatch =
  (): React.Dispatch<ReportGeneratorActions> => {
    const context = React.useContext(ReportGeneratorDispatchContext);

    if (!context) {
      throw new Error(
        'useReportGeneratorContextDispatch must be used inside ReportGeneratorContextProvider'
      );
    }

    return context;
  };

export const ReportGeneratorContextProvider: React.FunctionComponent<
  ReportGeneratorContextProviderType
> = ({
  initialValue = {
    documents: [],
    config: {
      file_count_limit: 0,
      file_size_limit: 0,
      allow_folder_upload: false,
      file_name_suffixes: [],
      file_types: [],
      mime_types: [],
    },
  },
  children,
}) => {
  const [data, dispatch] = useReportGeneratorState(initialValue);

  return (
    <ReportGeneratorContext.Provider
      value={React.useMemo(() => ({ data }), [data])}
    >
      <ReportGeneratorDispatchContext.Provider value={dispatch}>
        {children}
      </ReportGeneratorDispatchContext.Provider>
    </ReportGeneratorContext.Provider>
  );
};

export type ReportGeneratorContextType = {
  data: ReportGeneratorState;
};

type ReportGeneratorContextProviderType = Record<string, unknown> & {
  initialValue?: ReportGeneratorState;
};
