// @ts-strict-ignore
import CheckCircle from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as React from 'react';

import { Divide } from '@/components/Atoms/Divide';
import { LinearStepper } from '@/components/Stepper';
import type { IntermediateStepType } from '@/features/ChatBotV2/hooks/useAskNeedl';
import { useLocationMatch } from '@/hooks/useLocationMatch';

export const BotStepper = ({ steps, loading }: BotStepperType) => {
  const [isLoading, setIsLoading] = React.useState(loading);
  const [showAccordion, setShowAccordion] = React.useState(loading);
  const { askNeedlPageMatch } = useLocationMatch();
  const isAskNeedlPage = !!askNeedlPageMatch;

  const toggleActiveAccordion = () => {
    setShowAccordion((prev) => !prev);
  };

  React.useEffect(() => {
    setIsLoading(loading);
    setShowAccordion(loading);
  }, [loading]);

  const stepperContent = (
    <LinearStepper
      className='px-6 py-4'
      activeStep={steps.length - 1}
      steps={steps.map((stepInfo, i) => ({
        id: stepInfo.step,
        title: stepInfo.step,
        icon:
          stepInfo.loading || (i === steps.length - 1 && isLoading) ? (
            <CircularProgress size={24} />
          ) : (
            <CheckCircle color='primary' fontSize='medium' />
          ),
      }))}
      orientation='vertical'
    />
  );

  if (!isAskNeedlPage) {
    if (!isLoading) {
      // hide stepper once answer is streamed
      return null;
    }

    return (
      <AccordionDetails
        sx={{
          padding: '2px',
          borderRadius: '8px',
        }}
      >
        {stepperContent}
      </AccordionDetails>
    );
  }

  return (
    <Accordion
      expanded={showAccordion}
      onChange={() => toggleActiveAccordion()}
      sx={{
        borderRadius: '8px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        border: '1px solid #dfe5e2',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon sx={{ color: '#2c2c2c' }} />}
        sx={{
          '& .MuiAccordionSummary-content': {
            margin: '4px 0',
          },
        }}
      >
        <span className='sub-heading-normal ml-2'>Pro</span>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '2px',
          borderRadius: '8px',
        }}
      >
        <Divide className='mx-4' />
        {stepperContent}
      </AccordionDetails>
    </Accordion>
  );
};

type BotStepperType = {
  steps: IntermediateStepType[];
  loading: boolean;
};
