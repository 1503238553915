import { AnimateSharedLayout } from 'framer-motion';
import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { PaginationButton, StatusText } from '@/atoms/Pagination';
import type { PaginationItems } from '@/types';

type PaginationType = Omit<React.HTMLAttributes<HTMLDivElement>, 'css'> & {
  count: number;
  paginationItems: PaginationItems;
  page: number;
  rowCount: number;
};

const Container = styled.div`
  ${tw`flex items-center py-1.5 px-3 flex-wrap justify-between gap-1.5`}
  isolation: isolate;
`;

const PaginationContainer = styled.nav`
  ${tw`flex ml-auto`}
`;

export const Pagination = ({
  count,
  page,
  paginationItems,
  rowCount,
  ...rest
}: PaginationType): JSX.Element => {
  return (
    <Container {...rest}>
      {count > 0 && (
        <StatusText count={count} page={page} rowCount={rowCount} />
      )}
      <AnimateSharedLayout>
        <PaginationContainer>
          {paginationItems.map((itemData, index) => (
            <PaginationButton key={index} {...itemData} />
          ))}
        </PaginationContainer>
      </AnimateSharedLayout>
    </Container>
  );
};
