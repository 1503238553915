import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { ML_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

const QUERY_KEY = ['report_config'];

const fetcher = async ({ signal }: QueryFunctionContext) => {
  const response = await axiosInstance.get<QueryData>(
    ML_API_ROUTES.reportGeneration.config(),
    { signal }
  );

  return response.data;
};

export const useFetchReportConfig = () => {
  return useQuery(QUERY_KEY, fetcher, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const usePreFetchReportConfig = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEY, fetcher, {
      staleTime: Infinity,
      cacheTime: Infinity,
    });
  }, [queryClient]);
};

export type ReportConfigType = {
  file_count_limit: number;
  file_name_suffixes: string[];
  file_size_limit: number;
  file_types: string[];
  mime_types: string[];
  allow_folder_upload: boolean;
};

type QueryData = {
  config: ReportConfigType;
};
