import type { Value } from '@udecode/plate';

// Helper: Parse inline formatting (e.g., **bold**)
const parseInlineFormatting = (
  text: string
): { text: string; bold?: boolean }[] => {
  text = text.replace(/&nbsp;/g, '\u00A0');

  const regex = /\*\*(.+?)\*\*/g;
  const parts: { text: string; bold?: boolean }[] = [];
  let lastIndex = 0;

  for (const match of text.matchAll(regex)) {
    const [matchedText, boldText] = match;
    const matchStart = match.index || 0;

    // Add text before the match
    if (lastIndex < matchStart) {
      parts.push({ text: text.slice(lastIndex, matchStart) });
    }

    // Add bold text
    parts.push({ text: boldText, bold: true });

    lastIndex = matchStart + matchedText.length;
  }

  // Add remaining text after the last match
  if (lastIndex < text.length) {
    parts.push({ text: text.slice(lastIndex) });
  }

  return parts;
};

// Helper: Parse headings
const parseHeading = (line: string): MarkdownNode | null => {
  const headingRegex = /^(#{1,6})\s+(.*)/;
  const match = line.match(headingRegex);
  if (match) {
    const level = match[1].length;
    const text = match[2];
    return { type: `h${level}`, children: parseInlineFormatting(text) };
  }
  return null;
};

// Helper: Parse tables
const parseTable = (tableLines: string[]): MarkdownNode => {
  const rows = tableLines
    .filter((line) => !/^(\|\s*-+\s*)+\|$/.test(line)) // Exclude separator lines
    .map((line) =>
      line
        .split('|')
        .slice(1, -1)
        .map((cell) => cell.trim())
    );

  const [header, ...body] = rows;

  return {
    type: 'table',
    children: [
      {
        type: 'tr',
        children: header.map((cell) => ({
          type: 'td',
          children: [{ type: 'p', children: parseInlineFormatting(cell) }],
        })),
      },
      ...body.map((row) => ({
        type: 'tr',
        children: row.map((cell) => ({
          type: 'td',
          children: [{ type: 'p', children: parseInlineFormatting(cell) }],
        })),
      })),
    ],
  };
};

// Helper: Parse lists
const parseList = (listLines: string[], ordered: boolean): MarkdownNode => ({
  type: ordered ? 'ol' : 'ul',
  children: listLines.map((line) => ({
    type: 'li',
    children: [
      {
        type: 'p',
        children: parseInlineFormatting(
          line.replace(/^\d+\.\s|^- /, '').trim()
        ),
      },
    ],
  })),
});

// Helper: Parse paragraphs
const parseParagraph = (line: string): MarkdownNode => ({
  type: 'p',
  children: parseInlineFormatting(line),
});

// Main parser
export const parseMarkdown = (markdown: string): Value => {
  try {
    const lines = markdown.split('\n');
    const nodes: Value = [];

    let i = 0;
    while (i < lines.length) {
      const line = lines[i].trim();

      // Check for headings
      const headingNode = parseHeading(line);
      if (headingNode) {
        nodes.push(headingNode);
        i++;
        continue;
      }

      // Check for tables
      const tableRegex = /^\|.*\|/;
      if (tableRegex.test(line)) {
        const tableLines: string[] = [];
        while (i < lines.length && tableRegex.test(lines[i])) {
          tableLines.push(lines[i]);
          i++;
        }
        nodes.push(parseTable(tableLines));
        continue;
      }

      // Check for lists
      const listRegex = /^(\d+\.\s|-\s)/;
      if (listRegex.test(line)) {
        const listLines: string[] = [];
        const ordered = /^\d+\.\s/.test(line);
        while (i < lines.length && listRegex.test(lines[i])) {
          listLines.push(lines[i]);
          i++;
        }
        nodes.push(parseList(listLines, ordered));
        continue;
      }

      // Fallback to paragraph
      if (line) {
        nodes.push(parseParagraph(line));
      }

      i++;
    }

    return nodes;
  } catch (error) {
    return [{ children: [{ text: markdown }], type: 'p' }];
  }
};

type MarkdownNode = {
  type: string;
  children: Array<
    MarkdownNode | { text: string; [key: string]: boolean | string }
  >;
};
