import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { ML_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

const QUERY_KEY = ['user-generated-reports'];

export const useFetchUserGeneratedReports = () => {
  return useQuery(
    QUERY_KEY,
    () =>
      axiosInstance.get<ResponseType>(ML_API_ROUTES.reportGeneration.index()),
    {
      select: (data) => {
        return data?.data?.reports;
      },
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: true,
    }
  );
};

export const useInvalidateUserGeneratedReports = () => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEY, {
      refetchInactive: true,
    });
  }, [queryClient]);
};

export const PLACEHOLDER_GENERATED_REPORTS: ReportType[] = Array.from(
  { length: 3 },
  (_, index) => ({
    isLoading: true,
    id: `${index}`,
    title: '',
    status: 'PENDING',
    time_taken: 0,
    report_type: '',
    rating: 0,
    comments: '',
    created_at: 0,
    updated_at: 0,
  })
);

export type ReportStatusType = 'FAILURE' | 'PENDING' | 'SUCCESS';

export type ReportType = {
  isLoading?: boolean;
  id: string;
  title: string;
  status: ReportStatusType;
  time_taken: number;
  report_type: string;
  rating: number;
  comments: string;
  created_at: number;
  updated_at: number;
};

type ResponseType = {
  reports: ReportType[];
};
