import { useUserStatus } from '@/hooks/query/useUserStatus';

export const useNewUser = () => {
  const { userStatusData, userStatusLoading, userStatusFetching } =
    useUserStatus();

  return {
    isNewUser: userStatusData?.data?.user_status === 'new',
    isLoading: userStatusLoading || userStatusFetching,
  };
};
