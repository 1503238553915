import * as yup from 'yup';

import { PreviewParamsYupSchema } from '@/components/Preview/OverlayPreview/constants';
import { CustomFeedParamsYupSchema } from '@/features/CustomFeedV2';
import { PortfolioParamsYupSchema } from '@/features/PortfolioManager/constants';

const OVERLAY_TYPES = {
  CustomFeed: 'custom-feed',
  Preview: 'preview',
  AskNeedl: 'ask-needl',
  Portfolio: 'portfolio',
  Report: 'report',
} as const;

type OverlayType = typeof OVERLAY_TYPES[keyof typeof OVERLAY_TYPES];

const OVERLAY_QUERY_PARAMS = {
  [OVERLAY_TYPES.CustomFeed]: CustomFeedParamsYupSchema,
  // todo: colocate schema later
  [OVERLAY_TYPES.Preview]: PreviewParamsYupSchema,
  // TODO : update schema, added a temporary schema for now
  [OVERLAY_TYPES.AskNeedl]: yup.object<{ type: 'ask-needl' }>().shape({
    type: yup.string().oneOf(['preview']).required(),
  }),
  [OVERLAY_TYPES.Portfolio]: PortfolioParamsYupSchema,
  [OVERLAY_TYPES.Report]: yup.object<{ 'report-id': string }>().shape({
    'report-id': yup.string().required(),
  }),
  // new params will be added here
};

export { OVERLAY_TYPES, OVERLAY_QUERY_PARAMS };
export type { OverlayType };
