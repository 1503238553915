import { useField } from 'formik';
import type { HTMLMotionProps, Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Tooltip } from '@/atoms/Tooltip/Tooltip';
import { IconButton } from '@/components/IconButton/IconButton';
import { useControlled } from '@/hooks/useControlled';
import { useForkRef } from '@/hooks/useForkRef';
import CloseIcon from '@/icons/close-lg.svg?react';
import type { FormikFieldValiadtionType } from '@/types';
import { runAll } from '@/utils/runAll';

type ExpandableInputType = Omit<HTMLMotionProps<'input'>, 'css'> & {
  /**
   * should the input be expanded by default
   */
  defaultExpanded?: boolean;
  /**
   * direction to grow
   */
  growDirection?: 'left' | 'right';
  /**
   * custom icon
   */
  icon: React.ReactNode;
  /**
   * icon button label
   */
  iconButtonLabel: string;
  /**
   * input label
   */
  label: string;
};

const StyledInput = styled(motion.input)<{ expanded: boolean }>`
  ${tw`block w-full body-sm px-2 py-1 pr-8 h-8 border-gray-500 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-black focus:border-black rounded`}
  ${({ expanded }) => expanded && tw`border border-gray-500`}
`;

const IconContainer = styled.span<{ expanded: boolean }>`
  ${tw`absolute top-0 bottom-0 flex items-center`}
  ${({ expanded }) => expanded && tw`right-2`}
  & > :is(svg, img) {
    ${tw`w-4 h-4`}
  }
  & > :is(svg) {
    ${tw`fill-current text-gray-500`}
  }
`;

const AnimatedStyledInputContainer = styled(motion.div)<
  Pick<ExpandableInputType, 'growDirection'>
>`
  ${tw`relative`}
  ${({ growDirection }) => growDirection === 'left' && tw`ml-auto`}
  ${({ growDirection }) => growDirection === 'right' && tw`mr-auto`}
`;

const variants: Variants = {
  open: {
    width: '100%',
    opacity: 1,
    transition: {
      mass: 5,
      damping: 100,
      stiffness: 10,
    },
  },
  closed: {
    width: '0%',
    opacity: 0,
    transition: { mass: 10 },
    overflow: 'hidden',
  },
};

const ExpandableInput = React.forwardRef<HTMLInputElement, ExpandableInputType>(
  (
    {
      className,
      defaultExpanded,
      defaultValue = '',
      name = 'expandble-input',
      growDirection = 'left',
      icon,
      iconButtonLabel,
      label,
      placeholder,
      autoComplete = 'off',
      onChange,
      onBlur,
      onFocus,
      value,
    },
    ref
  ) => {
    const [openSearch, setOpenSearch] = React.useState(
      defaultExpanded ?? !!(defaultValue || value)
    );
    const [focused, setFocus] = React.useState(
      defaultExpanded ?? !!(defaultValue || value)
    );
    const internalInputRef = React.useRef<HTMLInputElement>(null);
    const forkedRef = useForkRef(ref, internalInputRef);

    const [inputValue, setValue, isControlled] = useControlled<string>({
      controlled: value as string,
      default: defaultValue as string,
      name: 'Input',
    });
    const firstRender = React.useRef(true);
    const controlledValueUpdated = React.useRef(false);

    const changeHandler = isControlled
      ? (event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event)
      : (event: React.ChangeEvent<HTMLInputElement>) =>
          setValue(event.target.value);

    const internalBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
      if (!event?.currentTarget?.value && !defaultExpanded) {
        setFocus(false);
      }
    };

    const closeHandler = () => {
      if (isControlled) {
        const event = { target: { value: '' } };
        onChange?.(event as React.ChangeEvent<HTMLInputElement>);
      } else {
        setValue('');
      }
      setOpenSearch(false);
    };

    React.useEffect(() => {
      if (openSearch) {
        internalInputRef.current?.focus();
      }
    }, [openSearch]);

    React.useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }
      if (!controlledValueUpdated.current) {
        setOpenSearch(true);
        setFocus(true);
        controlledValueUpdated.current = true;
      }
    }, [value]);

    if (!openSearch)
      return (
        <Tooltip label='Search'>
          <IconButton
            onClick={() => {
              setOpenSearch(true);
              setFocus(true);
            }}
            size='small'
            label={iconButtonLabel}
            type='button'
            id='expandable-input-button'
          >
            {icon}
          </IconButton>
        </Tooltip>
      );

    return (
      <>
        <label className='sr-only' htmlFor={`${name}_${label}`}>
          {label}
        </label>
        <AnimatedStyledInputContainer
          initial={defaultExpanded ? 'open' : 'closed'}
          animate={focused ? 'open' : 'closed'}
          growDirection={growDirection}
          variants={variants}
          onAnimationComplete={(d) => {
            if (d === 'closed' && !defaultExpanded) setOpenSearch(false);
          }}
        >
          <StyledInput
            autoComplete={autoComplete}
            className={className}
            expanded={openSearch}
            ref={forkedRef}
            name={name}
            onBlur={runAll(internalBlurHandler, onBlur)}
            onChange={changeHandler}
            onFocus={onFocus}
            placeholder={placeholder}
            value={inputValue}
            id={`${name}_${label}`}
          />
          <Tooltip label={value ? 'Clear & Close' : 'Close'}>
            <IconContainer
              expanded
              onClick={closeHandler}
              id='expandable-clear-search'
            >
              <CloseIcon />
            </IconContainer>
          </Tooltip>
        </AnimatedStyledInputContainer>
      </>
    );
  }
);

const FormikExpandableInput = React.forwardRef<
  HTMLInputElement,
  ExpandableInputType & FormikFieldValiadtionType
>(({ name, validate, ...rest }, ref) => {
  const [field] = useField({ name: name ?? '', validate });

  return <ExpandableInput ref={ref} {...field} {...rest} name={name} />;
});

export type { ExpandableInputType };
export { ExpandableInput, FormikExpandableInput };
