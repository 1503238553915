import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export type CountTagType = Omit<
  React.HTMLAttributes<HTMLSpanElement>,
  'css'
> & {
  count?: number;
  children?: React.ReactNode;
  circular?: boolean;
  tagColor?: string;
  textColor?: string;
};

const StyledText = styled.span`
  ${tw`grid place-content-center py-0.5 px-1`}
  min-width: 24px;
  min-height: 24px;
`;

export const CountTag = ({
  count,
  children,
  circular = false,
  tagColor = 'bg-white',
  textColor = 'text-gray-700',
  className = '',
  ...rest
}: CountTagType): JSX.Element => {
  return (
    <StyledText
      className={`sub-heading-sm ${tagColor} ${textColor} ${
        circular ? 'rounded-full' : 'rounded'
      } ${className}`}
      {...rest}
    >
      {children || count}
    </StyledText>
  );
};
