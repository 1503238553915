import type { Transition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import tw from 'twin.macro';

import SortIcon from '@/icons/down_arrow.svg?react';

import type { SortArrowType } from './common';

type StyledType = {
  rotateIcon?: boolean;
};

const Arrow = styled(SortIcon)<StyledType>`
  ${tw`w-4 h-4`}
`;

const enterExitTransition: Transition = {
  type: 'spring',
  stiffness: 200,
  damping: 30,
};

const rotateTransition: Transition = {
  type: 'spring',
  damping: 20,
};

export const SortArrow = <T extends Record<string, unknown>>({
  column,
}: SortArrowType<T>): JSX.Element => {
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {column.isSorted ? (
        <motion.span
          transition={enterExitTransition}
          initial={{ opacity: 0, y: -4 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 4 }}
        >
          <motion.div
            initial={false}
            animate={{ rotate: column.isSortedDesc ? 360 : 180 }}
            transition={rotateTransition}
          >
            <Arrow />
          </motion.div>
        </motion.span>
      ) : null}
    </AnimatePresence>
  );
};
