import type { UseSortByColumnProps } from 'react-table';
import styled from 'styled-components';
import tw from 'twin.macro';

import ExpandIcon from '@/icons/expand_bottom.svg?react';

type IndentType = {
  depth?: number;
};

type SortArrowType<T extends Record<string, unknown>> = {
  /**
   *sort column props
   */
  column: UseSortByColumnProps<T>;
};

const StyledExpandIcon = styled(ExpandIcon)`
  ${tw`h-6 w-6 p-1.5 -mt-2`}
`;

const IndentContainer = styled.div<IndentType>`
  ${tw`flex items-center`}
  ${({ depth = 0 }) => depth === 0 && tw`space-x-4`}
  ${({ depth = 0 }) => depth > 0 && tw`space-x-2`}
  ${({ depth = 0 }) => depth > 0 && tw`ml-2`}
`;

const TableStyling = styled.div`
  ${tw`block overflow-auto`}

  .table {
    ${tw`border-none w-full`}

    .thead {
      ${tw`overflow-x-hidden overflow-y-auto border-b border-gray-300 sub-heading-sm-bold bg-gray-200`}
    }
    .tr {
      ${tw`items-center`}

      .td {
        ${tw`px-3 py-1.5`}
      }
      .th {
        ${tw`px-3 py-2`}
      }
    }
    .tbody {
      .tr {
        ${tw`rounded transition-colors duration-200 body-sm`}

        &[data-selected="true"] {
          ${tw`bg-gray-100`}
        }

        &:hover {
          ${tw`bg-gray-100`}
        }
      }
    }
    [data-reach-custom-checkbox-container] {
      ${tw`block`}
    }
  }
`;

const TableWrapperWithBorder = styled.div`
  ${tw`flex flex-col rounded-md border border-gray-300 divide-y divide-gray-300`}
`;

export type { SortArrowType };
export {
  IndentContainer,
  StyledExpandIcon,
  TableStyling,
  TableWrapperWithBorder,
};
