import { Icon } from '@/components/Atoms/Icon';
import { Button } from '@/components/Button/Button';
import DownloadIcon from '@/icons/download-bracket.svg?react';
import NeedlNoteLogo from '@/logos/needlnote.svg?react';

export const SaveToNoteButton = ({
  saveToNoteHandler,
  loading,
  disabled,
}: SaveToNoteButtonType) => {
  return (
    <div className='flex-grow-0'>
      <Button
        disabled={disabled}
        loading={loading}
        variant='secondary'
        size='small'
        onClick={saveToNoteHandler}
        className='bg-white'
      >
        <span className='flex items-center gap-x-2'>
          <Icon size='xs'>
            <NeedlNoteLogo />
          </Icon>
          Save to Notes
        </span>
      </Button>
    </div>
  );
};

export const ExportToPdfButton = ({
  exportAsPDFHandler,
  loading,
  disabled,
}: ExportToPdfButtonType) => {
  return (
    <div className='flex-grow-0'>
      <Button
        disabled={disabled}
        loading={loading}
        variant='secondary'
        size='small'
        onClick={exportAsPDFHandler}
        className='bg-white'
      >
        <span className='flex items-center gap-x-2'>
          <Icon size='xs'>
            <DownloadIcon className='fill-current' />
          </Icon>
          Export as PDF
        </span>
      </Button>
    </div>
  );
};

type SaveToNoteButtonType = {
  saveToNoteHandler: () => void;
  loading: boolean;
  disabled: boolean;
};

type ExportToPdfButtonType = {
  exportAsPDFHandler: () => void;
  loading: boolean;
  disabled: boolean;
};
