import * as React from 'react';

type UseControlledType<T = unknown> = {
  /**
   * value if it's controlled
   */
  controlled: T | undefined;
  /**
   * default value when uncontrolled
   */
  default: T | undefined;
  /**
   * component name
   */
  name: string;
  /**
   * name of the state variable displayed in warnings.
   */
  state?: string;
};

export function useControlled<T>({
  controlled,
  default: defaultProp,
  name,
  state = 'value',
}: UseControlledType<T>): [T | undefined, (val: T) => void, boolean] {
  const { current: isControlled } = React.useRef(controlled !== undefined);
  const { current: defaultValue } = React.useRef(defaultProp);
  const [valueState, setValue] = React.useState(defaultProp);

  const value = isControlled ? controlled : valueState;

  if (!import.meta.env.PROD)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (isControlled !== (controlled !== undefined))
        console.error(
          [
            `A component is changing the ${
              isControlled ? 'un-' : ''
            }controlled ${state} state of ${name} to ${
              isControlled ? '' : 'un-'
            }controlled.`,
            'Elements should not switch from uncontrolled to controlled (or vice versa)',
            "The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.",
            'More info: https://fb.me/react-controlled-components',
          ].join('\n')
        );
    }, [controlled, state, name, isControlled]);

  React.useEffect(() => {
    if (
      !isControlled &&
      JSON.stringify(defaultValue) !== JSON.stringify(defaultProp)
    )
      console.error(
        [
          `A component is changing the default ${state} state of an uncontrolled ${name} after being initialized.`,
        ].join('\n')
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultProp)]);

  const setValueIfUncontrolled = React.useCallback(
    (newValue: T) => {
      if (!isControlled) setValue(newValue);
    },
    [isControlled]
  );

  return [value, setValueIfUncontrolled, isControlled];
}
