// @ts-strict-ignore
import ErrorIcon from '@mui/icons-material/Cached';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import type * as React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { TagsContainer } from '@/atoms/TagsContainer/TagsContainer';
import { Tooltip } from '@/atoms/Tooltip/Tooltip';
import { IconContainer } from '@/components/Atoms/IconContainer/IconContainer';
import { IconButton } from '@/components/IconButton/IconButton';
import {
  useReportGeneratorContext,
  useReportGeneratorContextDispatch,
} from '@/features/ReportGeneration/context/ReportGenerator';
import RemoveIcon from '@/icons/close-lg.svg?react';
import { getMimeIcon } from '@/utils/getMimeIcons';

export const SelectedFiles = () => {
  const context = useReportGeneratorContext();
  const dispatch = useReportGeneratorContextDispatch();

  return (
    <TagsContainer
      className='bg-white rounded-md p-2'
      tags={context.data.documents.map(({ file }) => ({
        value: file.name,
        onClick: () => dispatch({ type: 'REMOVE_DOCUMENT', payload: { file } }),
      }))}
    >
      {({ onClick, value }) => {
        const docIndex = context.data.documents.findIndex(
          ({ file }) => file.name === value
        );
        const { uploadProgress, uploadStatus } =
          context.data.documents[docIndex];

        return (
          <FileItem
            key={value}
            fileName={value}
            uploadProgress={uploadProgress}
            uploadStatus={uploadStatus}
            onRemove={onClick}
          />
        );
      }}
    </TagsContainer>
  );
};

export const FileItem = ({
  fileName,
  uploadProgress,
  uploadStatus,
  onRemove,
}: FileItemType) => {
  const MimeIcon = getMimeIcon('pdf');

  return (
    <div className='flex items-center max-w-[300px] py-1 px-2 my-[2px] bg-gray-50 rounded-md'>
      <Tooltip label={fileName}>
        <span className='flex-grow body-sm truncate text-gray-900'>
          {fileName}
        </span>
        <MimeIcon className='flex-shrink-0 w-6 h-6 fill-current text-gray-900 ml-1' />
      </Tooltip>
      {onRemove ? (
        <IconButton onClick={onRemove} size='xs'>
          <RemoveIcon />
        </IconButton>
      ) : null}
      {uploadStatus ? (
        <UploadStatusIcon
          uploadProgress={uploadProgress}
          uploadStatus={uploadStatus}
        />
      ) : null}
    </div>
  );
};

const UploadStatusIcon = ({
  uploadProgress,
  uploadStatus,
}: UploadStatusIconType) => {
  return (
    <IconContainer className='w-5 h-5 text-emerald-900'>
      {uploadStatus === 'FAIL' ? (
        <ErrorIcon style={{ color: 'red' }} />
      ) : uploadStatus === 'DONE' || uploadProgress >= 100 ? (
        <CheckCircleIcon style={{ color: '#367d8d' }} />
      ) : uploadProgress > 0 ? (
        <CircularProgressbar
          styles={buildStyles({ pathColor: '#367d8d' })}
          value={uploadProgress}
        />
      ) : null}
    </IconContainer>
  );
};

type UploadStatusIconType = {
  uploadProgress: number;
  uploadStatus: 'DONE' | 'FAIL' | 'PENDING';
};

type FileItemType = {
  fileName: string;
  uploadProgress?: number;
  uploadStatus?: 'DONE' | 'FAIL' | 'PENDING';
  onRemove?: (e: React.MouseEvent) => void;
};
