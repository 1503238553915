import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { ML_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

import type { ReportStatusType } from './useFetchUserGeneratedReports';

const QUERY_KEY = ['generated-report'];

export const useFetchGeneratedReport = (reportId: string) => {
  return useQuery(
    [...QUERY_KEY, reportId],
    () =>
      axiosInstance.get<ResponseType>(
        ML_API_ROUTES.reportGeneration.reportInfo(reportId)
      ),
    {
      select: (data) => {
        return data?.data?.report;
      },
    }
  );
};

export const useInvalidateGeneratedReport = () => {
  const queryClient = useQueryClient();

  return React.useCallback(
    (reportId: string) => {
      return queryClient.invalidateQueries([...QUERY_KEY, reportId], {
        refetchInactive: true,
      });
    },
    [queryClient]
  );
};

export type ReportInfoType = {
  id: string;
  created_at: number;
  updated_at: number;
  comments: string;
  rating: number;
  report_type: string;
  payload: {
    documents: {
      bucket: string;
      key: string;
      object_location: string;
      metadata: {
        fileName: string;
        guid: string;
        mimeType: string;
      };
    }[];
  };
  status: ReportStatusType;
  result: {
    error?: string;
    content: string;
    job_id: string;
    raw_data: {
      placeholder: {
        key: string;
      };
    };
    time_taken: number;
    title: string;
  };
};

type ResponseType = {
  report: ReportInfoType;
};
