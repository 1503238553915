import * as React from 'react';

import { CitationBase } from '@/components/Citation';
import type { AskNeedlCitationType } from '@/features/ChatBotV2/hooks/useAskNeedl';

export const Citation = ({
  document_id,
  access_key,
  category_tab,
  source,
  id,
  context,
  title_label,
  source_label,
  content_only,
  highlight_indexes,
  unix,
}: AskNeedlCitationType) => {
  const href = React.useMemo(() => {
    if (source && document_id && access_key && category_tab) {
      return `/preview/${source}/${document_id}?${new URLSearchParams([
        ['access_key', access_key],
        ['category_tab', category_tab],
      ])}`;
    }
  }, [source, document_id, access_key, category_tab]) as string;

  return (
    <CitationBase
      href={href}
      id={id}
      context={context}
      content_only={content_only}
      title_label={title_label}
      source_label={source_label}
      highlight_indexes={highlight_indexes}
      unix={unix}
    />
  );
};
