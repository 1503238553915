import * as Tags from '@/atoms/Tags/Tags';
import { ListOverflow } from '@/components/ListOverflow';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import type { CommonComponentType } from '@/types';

export type TagsContainerType = CommonComponentType & {
  tags: Tags.TagElementType[];
  children?: Tags.TagRenderProp;
};

export const TagsContainer = ({
  tags,
  className,
  children,
  style = {},
}: TagsContainerType) => {
  const { mobile } = useResponsiveConstants();

  const tagsCount = mobile ? 1 : 5;

  const restTagsCount = tags.length > tagsCount ? tags.length - tagsCount : 0;
  const restTagsArray = tags.slice(tagsCount);

  if (tags.length === 0) {
    return null;
  }

  return (
    <div
      className={`flex flex-wrap items-start gap-x-2 gap-y-1 ${className}`}
      style={style}
    >
      <Tags.Tags tags={tags} tagsCount={tagsCount}>
        {children}
      </Tags.Tags>
      <ListOverflow count={restTagsCount}>
        <Tags.Tags tags={restTagsArray}>{children}</Tags.Tags>
      </ListOverflow>
    </div>
  );
};

export const Tag = Tags.Tag;
