import {
  useActiveReportId,
  useFetchGeneratedReport,
} from '@/features/ReportGeneration/hooks';

export const ReportHeader = () => {
  const activeReportId = useActiveReportId();
  const { data: report } = useFetchGeneratedReport(activeReportId);

  return (
    <div className='flex justify-between items-center'>
      <div className='headline-normal-bold'>Report Summary</div>
      <div className='body-normal italic underline'>
        Time taken: {getTimeTaken(report?.result?.time_taken ?? 0)}
      </div>
    </div>
  );
};

const getTimeTaken = (seconds: number) => {
  return `${(seconds / 60).toFixed(2)}min`;
};
