import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper, { type StepperProps } from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const escapeId = (id: string) => CSS.escape(id);

export const LinearStepper = ({
  steps,
  activeStep,
  orientation = 'horizontal',
  children,
  className = '',
}: LinearStepperProps) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (containerRef.current && orientation === 'vertical') {
      const activeStepElementId = escapeId(
        `linear-stepper-step-${steps[activeStep]?.id}`
      );
      const activeStepElement = containerRef.current.querySelector(
        `#${activeStepElementId}`
      );
      if (activeStepElement) {
        activeStepElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [activeStep, orientation, steps]);

  return (
    <div ref={containerRef} className={`p-4 ${className}`}>
      <Stepper activeStep={activeStep} orientation={orientation}>
        {steps.map((step) => {
          const { id, title, optional, icon } = step;
          const labelProps: { optional?: React.ReactNode } = {};
          if (optional) {
            labelProps.optional = (
              <Typography variant='caption'>Optional</Typography>
            );
          }

          return (
            <Step key={id} id={`linear-stepper-step-${id}`}>
              <StepLabel {...labelProps} {...(icon ? { icon } : {})}>
                {typeof children === 'function' ? children(step) : title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export type LinearStepperProps = {
  steps: StepType[];
  orientation?: StepperProps['orientation'];
  activeStep: number;
  children?: (step: StepType) => React.ReactNode;
  className?: string;
};

export type StepType = {
  id: string;
  title: string;
  optional?: boolean;
  icon?: React.ReactNode;
};
