import ErrorStateImage from '@/assets/Error.webp';
import { EmptyState } from '@/components/EmptyState';
import { RichMessage } from '@/features/ChatBotV2/components/Message';
import {
  useActiveReportId,
  useFetchGeneratedReport,
} from '@/features/ReportGeneration/hooks';

export const ReportContent = () => {
  const activeReportId = useActiveReportId();
  const { data: report } = useFetchGeneratedReport(activeReportId);

  if (report?.result?.error === 'true') {
    return (
      <div>
        <EmptyState
          title='This one is on us and we are looking into it.'
          illustrationSrc={ErrorStateImage}
          content={`Unable to generate the summary at this time. Our team will review this case to improve the system's capabilities. Please try again or proceed manually.`}
        />
      </div>
    );
  }

  return (
    <div className='flex-grow bg-white p-4 rounded-md overflow-auto'>
      <RichMessage
        markdown={report?.result?.content ?? ''}
        className='space-y-4'
      />
    </div>
  );
};
