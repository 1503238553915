import { useField } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { useControlled } from '@/hooks/useControlled';
import { useForkRef } from '@/hooks/useForkRef';
import type { FormikFieldValiadtionType } from '@/types';

import type { CommonTypes } from './common';
import {
  BaseInputStyle,
  LabelHiddenStyle,
  LabelStyle,
  NoErrorInputStyle,
} from './common';

export type TextAreaType = CommonTypes &
  Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'css'> & {
    rows?: number;
  };

const StyledTextArea = styled.textarea<TextAreaType>(() => [
  BaseInputStyle,
  NoErrorInputStyle,
  tw`min-h-[5rem] max-h-[10rem]`,
]);

const StyledLabel = styled.label<Pick<TextAreaType, 'labelHidden'>>`
  ${({ labelHidden }) => (labelHidden ? LabelHiddenStyle : LabelStyle)}
`;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaType>(
  (
    {
      autoComplete = 'off',
      defaultValue = '',
      label,
      labelHidden,
      name,
      readOnly,
      required,
      value,
      type = 'text',
      rows = 3,
      onChange,
      ...rest
    },
    ref
  ) => {
    const internalTextAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const [TextAreaValue, setValue, isControlled] = useControlled({
      controlled: value,
      default: String(defaultValue),
      name: 'TextArea',
    });
    const changeHandler = isControlled
      ? onChange
      : (event: React.ChangeEvent<HTMLTextAreaElement>) =>
          setValue(event.target.value);

    const TextAreaRef = useForkRef(ref, internalTextAreaRef);

    return (
      <div>
        {label ? (
          <StyledLabel
            htmlFor={name}
            labelHidden={labelHidden}
            data-needl-input-label
          >
            {label}
            {required ? <span className='text-red-600'>&nbsp;*</span> : null}
          </StyledLabel>
        ) : null}
        <StyledTextArea
          autoComplete={autoComplete}
          ref={TextAreaRef}
          rows={rows}
          name={name}
          id={name}
          type={type}
          value={TextAreaValue}
          onChange={changeHandler}
          readOnly={readOnly}
          required={required}
          data-needl-input
          {...rest}
        />
      </div>
    );
  }
);

export const FormikTextAreaField = React.forwardRef<
  HTMLTextAreaElement,
  FormikFieldValiadtionType &
    Omit<TextAreaType, 'name'> &
    Required<Pick<TextAreaType, 'name'>>
>(({ name, ...rest }, ref) => {
  const [field] = useField({ name });

  return <TextArea ref={ref} {...rest} {...field} />;
});
