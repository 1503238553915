import { makeStyles } from '@material-ui/core';
import type { SwitchProps } from '@material-ui/core/Switch';
import MUISwitch from '@material-ui/core/Switch';

const useStyles = makeStyles(() => {
  return {
    greenColor: {
      color: 'var(--green-900) !important',
    },
    greenBg: {
      backgroundColor: 'var(--green-900) !important',
    },
    redColor: {
      color: 'var(--red-900) !important',
    },
    redBg: {
      backgroundColor: 'var(--red-900) !important',
    },
  };
});

export const Switch = (props: SwitchProps) => {
  const classes = useStyles();

  return (
    <MUISwitch
      classes={{
        switchBase: props.checked ? classes.greenColor : classes.redColor,
        track: props.checked ? classes.greenBg : classes.redBg,
      }}
      {...props}
    />
  );
};
