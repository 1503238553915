import type { Transition } from 'framer-motion';
import { motion } from 'framer-motion';
import * as React from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

import PreviousIcon from '@/icons/expand_left.svg?react';
import NextIcon from '@/icons/expand_right.svg?react';
import LastIcon from '@/icons/so-next.svg?react';
import FirstIcon from '@/icons/so-previous.svg?react';
import type { PaginationItem, PaginationItemTypes } from '@/types';

export type PaginationButtonType = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  keyof PaginationItem | 'css'
> &
  PaginationItem;

const CommonStyling = css`
  ${tw`px-3 py-1.5 rounded grid place-content-center text-gray-700 disabled:text-gray-300 disabled:cursor-not-allowed`}
`;

const EllipsisContainer = styled.span`
  ${CommonStyling}
`;

const StyledHighlighter = styled.span`
  ${tw`absolute inset-0 rounded ring-1 ring-green-900 z-10`}
`;
const Highlight = motion(StyledHighlighter);

const Button = styled.button<{ selected: boolean }>`
  ${CommonStyling}
  ${tw`relative transition-colors delay-100`}

  ${({ selected }) => selected && tw`bg-green-100`}

  &:not(:disabled) {
    ${({ selected }) => !selected && tw`hover:bg-gray-100`}
  }

  > svg {
    ${tw`fill-current w-6 h-6`}
  }
`;

const getIcon = (type: PaginationItemTypes) => {
  switch (type) {
    case 'first':
      return <FirstIcon />;
    case 'last':
      return <LastIcon />;
    case 'next':
      return <NextIcon />;
    case 'previous':
      return <PreviousIcon />;
    default:
      return null;
  }
};

const transition: Transition = {
  type: 'spring',
  damping: 20,
  stiffness: 200,
};

export const PaginationButton = React.forwardRef<
  HTMLButtonElement,
  PaginationButtonType
>(({ page, selected, type, ...rest }, ref) => {
  if (type === 'end-ellipsis' || type === 'start-ellipsis') {
    return <EllipsisContainer>...</EllipsisContainer>;
  } else if (type === 'page') {
    return (
      <Button ref={ref} {...rest} selected={selected}>
        {page}
        {selected ? (
          <Highlight
            initial={false}
            animate={{ opacity: 1 }}
            layoutId='page-selection'
            transition={transition}
          />
        ) : null}
      </Button>
    );
  } else {
    return (
      <Button ref={ref} {...rest} selected={false}>
        {getIcon(type)}
      </Button>
    );
  }
});
