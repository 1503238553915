import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { OVERLAY_TYPES, useOverlayQueryParams } from '@/components/AppOverlay';
import { REPORT_GENERATION_ROUTES } from '@/nav-routes';
import { useNavigate } from '@/router';

export const useOpenReportOverlay = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const openReportOverlay = React.useCallback(
    (reportId: string) => {
      const overlayParams = new URLSearchParams(location.search);

      overlayParams.set('overlay-type', OVERLAY_TYPES.Report);
      overlayParams.set('overlay-report-id', reportId);

      navigate.push(
        {
          pathname: REPORT_GENERATION_ROUTES.DASHBOARD,
          search: overlayParams.toString(),
        },
        null,
        {
          removeOldState: false,
        }
      );
    },
    [location.search, navigate]
  );

  return openReportOverlay;
};

export const useActiveReportId = () => {
  const queryParams = useOverlayQueryParams();
  const reportId =
    queryParams &&
    'report-id' in queryParams &&
    ((queryParams['report-id'] ?? '') as string);

  if (!reportId) {
    throw new Error('Report Id not found in overlay query params');
  }

  return reportId;
};
