import * as React from 'react';

export type BaseButtonType = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'css'
>;

export const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonType>(
  ({ children, ...rest }, ref) => {
    return (
      <button ref={ref} {...rest}>
        {children}
      </button>
    );
  }
);
