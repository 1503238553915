import type * as React from 'react';

import Audio from '@/mimes/audio.svg?react';
import Document from '@/mimes/doc.svg?react';
import Gif from '@/mimes/gif.svg?react';
import Image from '@/mimes/img.svg?react';
import Other from '@/mimes/other.svg?react';
import PDF from '@/mimes/pdf.svg?react';
import Phonebook from '@/mimes/phonebook.svg?react';
import PPT from '@/mimes/ppt.svg?react';
import Video from '@/mimes/video.svg?react';
import XLS from '@/mimes/xls.svg?react';
import Zip from '@/mimes/zip.svg?react';

const map: Record<string, React.FunctionComponent> = {
  doc: Document,
  docx: Document,
  documents: Document,
  contacts: Phonebook,
  conversations: Phonebook,
  pdf: PDF,
  png: Image,
  image: Image,
  jpg: Image,
  jpeg: Image,
  pneg: Image,
  gif: Gif,
  tif: Video,
  mp4: Video,
  wav: Video,
  mkv: Video,
  vlc: Video,
  mpeg: Video,
  video: Video,
  media: Video,
  'video/quicktime': Video,
  mp3: Audio,
  audio: Audio,
  pptx: PPT,
  ppt: PPT,
  csv: XLS,
  xlsx: XLS,
  xls: XLS,
  spreadsheet: XLS,
  zip: Zip,
  zipped: Zip,
};

export const getMimeIcon = (mime: string) => {
  const el = map[mime];
  let Component = Other;

  if (el) {
    Component = el;
  }

  return Component;
};
