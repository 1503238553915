import tw, { css, styled } from 'twin.macro';

import LinkRightArrow from '@/icons/right_arrow.svg?react';
import { multipleStringHighlights } from '@/utils/multipleStringHighlight';

export const Citation = ({
  firstHeading,
  secondHeading,
  text,
  highlightText,
  link,
  date,
}: CitationType) => {
  return (
    <CitationWrapper>
      <CiationHeader
        firstHeading={firstHeading}
        secondHeading={secondHeading}
      />
      <CitationBody text={text} highlightText={highlightText} />
      <CitationLink link={link} date={date} />
    </CitationWrapper>
  );
};

const CitationWrapper = styled.div`
  ${tw`
    p-4 rounded-lg bg-white flex flex-col gap-2
    border border-gray-300
  `}
  ${css`
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.15);
    width: min(95vw, 500px);
  `}
`;

const CiationHeadingText = styled.span`
  ${tw`text-black font-medium text-sm`}
`;

const CiationHeader = ({
  firstHeading,
  secondHeading,
}: Pick<CitationType, 'firstHeading' | 'secondHeading'>) => {
  if (!firstHeading && !secondHeading) return null;

  return (
    <div className='flex pr-2 items-center'>
      {firstHeading ? (
        <div className='overflow-hidden whitespace-nowrap text-ellipsis flex-shrink'>
          {<CiationHeadingText>{firstHeading}</CiationHeadingText>}
        </div>
      ) : null}
      {firstHeading && secondHeading ? (
        <div className='px-5 text-gray-300 flex-none'>
          <div className='w-[1px] h-5 bg-gray-300'></div>
        </div>
      ) : null}
      {secondHeading ? (
        <div className='overflow-hidden whitespace-nowrap text-ellipsis flex-shrink-0 max-w-[50%]'>
          {<CiationHeadingText>{secondHeading}</CiationHeadingText>}
        </div>
      ) : null}
    </div>
  );
};

const CitationLink = ({ link, date }: CitationLinkType) => {
  return (
    <div className='flex items-center w-full'>
      <div className='flex-1 text-xs text-gray-700'>{date}</div>

      <div className='px-3 py-1'>
        <a
          className='text-emerald-900 text-xs font-medium flex gap-1 items-center leading-5'
          href={link}
          target='_blank'
          rel='noreferrer'
        >
          View Reference
          <span className='w-4 h-4'>
            <LinkRightArrow />
          </span>
        </a>
      </div>
    </div>
  );
};

const CitationEllipsesContainer = styled.div`
  ${tw`
    flex-1 min-w-0 max-h-21 h-full overflow-hidden text-sm

    relative w-full whitespace-normal pr-2.5 mr-[-12px] box-border

    before:content-['...'] before:absolute before:right-0 before:bottom-0 before:bg-gradient-to-r before:bg-white

    after:content-[''] after:absolute after:right-0 after:w-3 after:h-4 mt-0.5 after:bg-white
  `}
`;

const CitationBody = ({
  text,
  highlightText,
}: Pick<CitationType, 'highlightText' | 'text'>) => {
  if (!text) return null;

  return highlightText && highlightText.length > 0 ? (
    <CitationEllipsesContainer>
      {multipleStringHighlights(text, highlightText, 'emerald')}
    </CitationEllipsesContainer>
  ) : (
    <CitationEllipsesContainer>{text}</CitationEllipsesContainer>
  );
};

export type CitationType = {
  firstHeading?: string;
  secondHeading?: string;
  text?: string;
  highlightText?: string[];
  link: string;
  date?: string;
};

type CitationLinkType = Pick<CitationType, 'date' | 'link'>;
