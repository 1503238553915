import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { BaseButton } from '@/atoms/BaseButton/BaseButton';
import { CountTag } from '@/atoms/CountTag';
import { Text } from '@/atoms/Text/Text';

export type ButtonCountType = {
  /**
   * button label
   */
  label?: string;
  /**
   * count of items
   * button is disabled if count is 0
   *
   * @default 0
   */
  count?: number;
  /**
   * onClick handler
   */
  onClick: (e: React.MouseEvent) => void;
};

const StyledBaseButton = styled(BaseButton)`
  ${tw`flex h-7 py-0.5 px-2 space-x-2 items-center disabled:cursor-not-allowed`}
`;

const StyledText = styled(Text)<{ disabled: boolean }>`
  ${tw`underline text-gray-900`}
  ${({ disabled }) => disabled && tw`text-gray-300`}
`;

export const ButtonCount = ({
  label,
  count = 0,
  onClick,
}: ButtonCountType): JSX.Element => {
  return (
    <StyledBaseButton disabled={count === 0} onClick={onClick}>
      <StyledText disabled={count === 0} size='xs'>
        {label}
      </StyledText>
      {count > 0 ? (
        <CountTag
          count={count}
          circular={true}
          tagColor='bg-emerald-900'
          textColor='text-white'
        />
      ) : null}
    </StyledBaseButton>
  );
};
