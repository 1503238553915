import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { CATEGORIES_API_ROUTE } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { isAppAllowed } from '@/components/ConnectApps/Common/appLibraryData';

import { useConfigUI } from './useFeatureConfig';

const QUERY_KEY = 'all-categories-info';

export const useFetchCategories = () => {
  const { isLoading: isConfigLoading, data: config } = useConfigUI();

  const {
    isLoading: isCategoriesDataLoading,
    isError: isCategoriesError,
    data: categoriesData,
    ...queryData
  } = useQuery([QUERY_KEY], fetcher, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const filteredData = React.useMemo(() => {
    if (!categoriesData?.data?.results || !config) {
      return null;
    }

    const filteredCategories = categoriesData.data.results.map((category) => ({
      ...category,
      sources: category.sources.filter((source) =>
        isAppAllowed(source.key, config)
      ),
    }));

    return {
      ...categoriesData,
      data: {
        ...categoriesData.data,
        results: filteredCategories,
      },
    };
  }, [categoriesData, config]);

  return React.useMemo(
    () => ({
      ...queryData,
      isLoading: isCategoriesDataLoading || isConfigLoading,
      isError: isCategoriesError,
      data: filteredData,
    }),
    [
      filteredData,
      isCategoriesError,
      isCategoriesDataLoading,
      isConfigLoading,
      queryData,
    ]
  );
};

export const usePrefetchCategories = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEY, {
      queryFn: fetcher,
      staleTime: Infinity,
      cacheTime: Infinity,
    });
  }, [queryClient]);
};

const fetcher = async () => {
  return axiosInstance.get<ResponseType>(CATEGORIES_API_ROUTE.index());
};

export type Source = {
  key: string;
  label: string;
  connected?: boolean;
  hasData?: boolean;
  disabled?: boolean;
};

export type Category = {
  key: string;
  label: string;
  sources: Source[];
};

type ResponseType = {
  results: Category[];
};
