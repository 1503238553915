// @ts-strict-ignore
import { Dialog } from '@reach/dialog';
import * as React from 'react';
import Lottie from 'react-lottie';

import { DEFAULT_LOTTIE_OPTIONS } from '@/constants/defaultLottieOptions';

export const AnimationOverlaySpinner = () => {
  return (
    <Dialog className='fixed inset-0'>
      <div className='bg-white opacity-80 inset-0 fixed'></div>
      <Wrapper>
        <div className='relative'>
          <AnimationSpinner size='medium' />
        </div>
        <div className='relative'>
          <TextCarosel />
        </div>
      </Wrapper>
    </Dialog>
  );
};

export const AnimationLoader = () => {
  return (
    <Wrapper>
      <AnimationSpinner />
      <div className='text-xl font-medium text-black'>
        Feed loading... Almost there
      </div>
    </Wrapper>
  );
};

const Wrapper: React.FunctionComponent = ({ children }) => {
  return (
    <div className='flex flex-col items-center justify-center h-full gap-y-6'>
      {children}
    </div>
  );
};

const AnimationSpinner = ({ size = 'small' }: AnimationType) => {
  const [animationData, setAnimationData] = React.useState(null);

  const loadAnimationData = async () => {
    try {
      const data = await import('./animationData.json');
      setAnimationData(data.default || data);
    } catch (error) {
      console.error('Error loading animation data:', error);
    }
  };

  React.useEffect(() => {
    loadAnimationData();
  }, []);

  if (!animationData) {
    return null;
  }

  return (
    <Lottie
      options={{ ...DEFAULT_LOTTIE_OPTIONS, animationData }}
      height={size === 'medium' ? 150 : 90}
      width={size === 'medium' ? 150 : 90}
    />
  );
};

const TextCarosel = () => {
  const texts = React.useMemo(
    () => [
      'Analyzing Your Inputs, Crafting Insights.',
      'Building Context, Generating Relevant Keywords.',
      'Tailoring Recommendations, Adding Sources.',
      'Applying Final Touches.',
    ],
    []
  );
  const delay = 2000;

  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const transitionInterval = setInterval(() => {
      if (currentIndex < texts.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(texts.length - 1);
      }
    }, delay);

    return () => clearInterval(transitionInterval);
  }, [currentIndex, texts]);

  return (
    <div className='flex flex-col gap-y-2 items-center text-black'>
      <div className='text-2xl font-medium'>{texts[currentIndex]}</div>
      <div className='body-lg'>
        This may take up to a few seconds, but it&apos;s worth the wait.
      </div>
    </div>
  );
};

type AnimationType = {
  size?: 'medium' | 'small';
};
