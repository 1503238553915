import { createTheme } from '@mui/material';

export const themeV6 = createTheme({
  palette: {
    primary: {
      main: '#006D77',
    },
    secondary: {
      main: '#FFE66D',
    },
    background: {
      default: '#FFF',
    },
    action: {
      hover: '#F6F4EC',
      hoverOpacity: 0.1,
      selected: '#F6F4EC',
    },
    text: {
      primary: '#3E5657',
      secondary: '#A3ACAD',
    },
  },
});
