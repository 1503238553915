import { makeStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import type * as React from 'react';
import type { FallbackProps } from 'react-error-boundary';

import { Button } from '@/components/legacy_Components/Form/Button/Button';

type ErrorBoundaryWithResetProps = {
  resetErrorBoundary: () => void;
  error?: Error;
};

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'grid',
    placeContent: 'center',
    border: '1px solid #ff000040',
    background: theme.palette.type === 'light' ? '#ff00000f' : '#ff000030',
    padding: '16px',
  },
  container: {
    textAlign: 'center',
  },
}));

export const ErrorBoundaryWithReset: React.ComponentType<FallbackProps> =
  function ({ resetErrorBoundary, error }: ErrorBoundaryWithResetProps) {
    const classes = useStyle();
    return (
      <div role='alert' className={classes.root}>
        <div className={classes.container}>
          <div>
            <ErrorOutline />
          </div>
          <p>{error?.message}</p>
          <p>
            Something went wrong, Please contact us at support@needl.ai and
            click to continue
          </p>
          <Button onClick={resetErrorBoundary}>go back</Button>
        </div>
      </div>
    );
  };
