import type * as React from 'react';

export const EmptyState = ({
  title,
  icon,
  content,
  illustrationFooter,
  illustrationSrc,
  description,
  isManager = false,
}: FeedEmptyStateProps) => {
  return (
    <div className='@container'>
      <div
        className={`flex flex-col-reverse items-center gap-6 px-8 py-8 rounded-md bg-gray-100 border border-solid border-gray-300 @md:grid @md:grid-cols-[1fr,clamp(200px,15vw,250px)] @3xl:grid @3xl:gap-4 @3xl:grid-cols-[1fr,1fr,clamp(300px,25vw,350px)] ${
          isManager ? 'mt-16' : 'mx-5 mb-4  @md:mt-16 @3xl:m-16'
        }`.trim()}
      >
        <div className='flex flex-col self-start items-start gap-3 @md:self-center @3xl:self-center @3xl:col-span-2 @3xl:gap-5'>
          {icon ? (
            <span className='w-8 @3xl:w-10 fill-current text-gray-900'>
              {icon}
            </span>
          ) : null}
          <p className='flex gap-2 items-center body-normal-bold text-gray-900 @3xl:headline-lg-bold'>
            {title}
          </p>
          {description ? (
            <p className='body-sm text-gray-900 @3xl:body-normal'>
              {description}
            </p>
          ) : null}
          {content}
        </div>
        <div className='flex flex-col items-center gap-3'>
          <img
            src={illustrationSrc}
            alt='Empty state illustration'
            className={`w-[clamp(250px,50vw,300px)] ${
              isManager ? '' : '@3xl:w-auto'
            }`}
          />
          {illustrationFooter}
        </div>
      </div>
    </div>
  );
};

type FeedEmptyStateProps = {
  /**
   * Container margin applied to the outermost div
   */
  isManager?: boolean;
  /**
   * Empty state title
   */
  title: React.ReactNode;
  /**
   * description
   */
  description?: React.ReactNode;
  /**
   * Icon component visible directly above the title.
   */
  icon?: React.ReactNode;
  /**
   * Empty state main content component
   */
  content?: React.ReactNode;
  /**
   * Illustration Footer Component
   */
  illustrationFooter?: React.ReactNode;
  /**
   * Illustration image source
   */
  illustrationSrc: string;
};
