import * as React from 'react';

import {
  type OverlayType,
  OVERLAY_TYPES,
  useOverlayType,
} from '@/components/AppOverlay';
import { useNavActionDispatch, useNavState } from '@/context/NavContext';

const useOverlayWidthDetails = (): OverlayWidthContextType => {
  const overlayType = useOverlayType();
  const [shiftWidth, setShiftWidth] = React.useState(0);

  const [overlayWidthVW, setOverlayWidthVW] = React.useState<
    Record<OverlayType, WidthDetailsType>
  >({
    [OVERLAY_TYPES.Preview]: {
      min: 80,
      max: 80,
      current: 80,
    },
    [OVERLAY_TYPES.AskNeedl]: {
      min: 35,
      max: 80,
      current: 35,
    },
    [OVERLAY_TYPES.CustomFeed]: {
      min: 50,
      max: 80,
      current: 50,
    },
    [OVERLAY_TYPES.Portfolio]: {
      min: 50,
      max: 80,
      current: 50,
    },
    [OVERLAY_TYPES.Report]: {
      min: 60,
      max: 80,
      current: 80,
    },
  } as Record<OverlayType, WidthDetailsType>);

  const setOverlayWidthVWDetails = ({
    widthType,
    width,
  }: SetOverlayWidthVWDetailsType) => {
    if (overlayType) {
      setOverlayWidthVW((prev) => ({
        ...prev,
        [overlayType]: {
          ...prev[overlayType],
          [widthType]: width,
        },
      }));
    }
  };

  const overlayWidthVWDetails = overlayType
    ? overlayWidthVW[overlayType]
    : {
        min: 0,
        max: 100,
        current: 50,
      };

  const handleOverlayMove = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();

    const clientX =
      e.type === 'mousemove'
        ? (e as MouseEvent).clientX
        : (e as TouchEvent).touches[0].clientX;

    const minWidthPercentage = overlayWidthVWDetails.min;
    const maxWidthPercentage = overlayWidthVWDetails.max;

    const newWidth = Math.min(
      Math.max(minWidthPercentage, 100 - (clientX / window.innerWidth) * 100),
      maxWidthPercentage
    );

    setOverlayWidthVWDetails({
      widthType: 'current',
      width: newWidth,
    });
  };

  const handleOverlayEnd = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    window.removeEventListener('mousemove', handleOverlayMove);
    window.removeEventListener('mouseup', handleOverlayEnd);
    window.removeEventListener('touchmove', handleOverlayMove);
    window.removeEventListener('touchend', handleOverlayEnd);
  };

  const handleOverlayMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    window.addEventListener('mousemove', handleOverlayMove);
    window.addEventListener('mouseup', handleOverlayEnd);
    window.addEventListener('touchmove', handleOverlayMove);
    window.addEventListener('touchend', handleOverlayEnd);

    handleOverlayMove(e as unknown as MouseEvent);
  };

  return {
    overlayWidthVWDetails,
    setOverlayWidthVWDetails,
    handleOverlayMouseDown,
    shiftWidth,
    setShiftWidth,
  };
};

const OverlayWidthContext = React.createContext<OverlayWidthContextType | null>(
  null
);

export const OverlayWidthProvider: React.FunctionComponent = ({ children }) => {
  const overlayWidthDetails = useOverlayWidthDetails();

  return (
    <OverlayWidthContext.Provider value={overlayWidthDetails}>
      {children}
    </OverlayWidthContext.Provider>
  );
};

export const useOverlayWidthContext = (): OverlayWidthContextType => {
  const value = React.useContext(OverlayWidthContext);
  const { state } = useNavState();
  const navDispatch = useNavActionDispatch();

  React.useEffect(() => {
    if (value) {
      const { overlayWidthVWDetails, setOverlayWidthVWDetails } = value;
      // on opening the left nav, set the overlay width to below 80vw
      if (state === 'open') {
        const finalWidth =
          overlayWidthVWDetails.current > 80
            ? (80 / 100) * overlayWidthVWDetails.current
            : overlayWidthVWDetails.current;
        setOverlayWidthVWDetails({
          widthType: 'current',
          width: finalWidth,
        });
      }

      // on increasing the overlay width beyond 80vw, close the left nav
      if (state === 'opened' && overlayWidthVWDetails.current > 80) {
        navDispatch({ type: 'close' });
      }
    }
  }, [state, navDispatch, value]);

  if (!value) {
    throw new Error(
      'useOverlayWidthContext must be used within an OverlayWidthProvider'
    );
  }

  return value;
};

type WidthDetailsType = {
  min: number;
  max: number;
  current: number;
};

type SetOverlayWidthVWDetailsType = {
  widthType: 'current' | 'max' | 'min';
  width: number;
};

type OverlayWidthContextType = {
  overlayWidthVWDetails: WidthDetailsType;
  setOverlayWidthVWDetails: (details: SetOverlayWidthVWDetailsType) => void;
  handleOverlayMouseDown: (e: React.MouseEvent | React.TouchEvent) => void;
  shiftWidth: number;
  setShiftWidth: React.Dispatch<React.SetStateAction<number>>;
};
