import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { FullScreenSpinner } from '@/components/Atoms/Spinner';
import { ErrorBoundary as Error } from '@/components/ErrorBoundaries/ErrorBoundary';
import {
  useActiveReportId,
  useFetchGeneratedReport,
} from '@/features/ReportGeneration/hooks';

import { Feedback } from './Feedback';
import { FilesAdded } from './FilesAdded';
import { ReportContent } from './ReportContent';
import { ReportFooter } from './ReportFooter';
import { ReportHeader } from './ReportHeader';

export const ReportOverlay = () => {
  const activeReportId = useActiveReportId();
  const { isLoading } = useFetchGeneratedReport(activeReportId);

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  return <ReportOverlayImpl />;
};

export const ReportOverlayImpl = () => {
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <React.Suspense fallback={<FullScreenSpinner />}>
        <div className='flex h-screen gap-6 p-4 box-border overflow-hidden'>
          <InputSection />
          <ReportSection />
        </div>
      </React.Suspense>
    </ErrorBoundary>
  );
};

const InputSection = () => {
  return (
    <div className='border-[3px] border-dashed border-gray-200 py-8 px-4 min-w-[320px] h-full overflow-auto box-border flex flex-col gap-8'>
      <FilesAdded />
      <Feedback />
    </div>
  );
};

const ReportSection = () => {
  return (
    <div className='flex-grow flex flex-col gap-2 shadow-2xl py-8 px-6 rounded-lg h-full overflow-auto box-border'>
      <ReportHeader />
      <div className='flex-grow flex flex-col gap-2 p-4 bg-gray-200 rounded-md shadow-md overflow-auto'>
        <ReportContent />
        <ReportFooter />
      </div>
    </div>
  );
};
