import * as React from 'react';

import {
  ASKNEEDL_FULLSCREEN_ROUTE,
  SEARCH_ROUTES,
  SOURCE_NAV_MANAGER_ROUTES,
} from '@/nav-routes';

import type { FeaturesConfigKey } from './query/useFeatureConfig';
import { useConfigUI } from './query/useFeatureConfig';

export const FALLBACK_DEFAULT_ROUTE =
  '/feeds/home' + (import.meta.env.VITE_DEFAULT_LANDING_PAGE || '');

// maintain sequence as per left nav
const FEATURES_NAV_ROUTES: FeaturesNavRouteType[] = [
  { feature: 'my_data', route: FALLBACK_DEFAULT_ROUTE },
  { feature: 'search', route: SEARCH_ROUTES.FULLSCREEN },
  { feature: 'ask_needl_full_screen', route: ASKNEEDL_FULLSCREEN_ROUTE },
  { feature: 'clipped_reports', route: '/needlbox/clipped_reports/all' },
  { feature: 'bookmarks', route: '/needlbox/bookmarks/all' },
  { feature: 'tags', route: '/needlbox/tags/all' },
  { feature: 'comments', route: '/needlbox/comments/all' },
  { feature: 'assistants', route: SOURCE_NAV_MANAGER_ROUTES.ASSISTANTS_ROUTE },
  {
    feature: 'report_generation',
    route: SOURCE_NAV_MANAGER_ROUTES.REPORT_GENERATION_ROUTE,
  },
];

export const useGetLandingPage = (): string => {
  const { data, isLoading } = useConfigUI();

  return React.useMemo(() => {
    if (isLoading || !data) {
      return FALLBACK_DEFAULT_ROUTE;
    }

    for (const { feature, route } of FEATURES_NAV_ROUTES) {
      if (data[feature]) {
        return route;
      }
    }

    return FALLBACK_DEFAULT_ROUTE;
  }, [data, isLoading]);
};

type FeaturesNavRouteType = {
  feature: FeaturesConfigKey;
  route: string;
};
