import type * as React from 'react';
import tw, { styled } from 'twin.macro';

import { escapeRegExp } from './escapeRegExp';

type ResultType = Array<JSX.Element | string>;

type HighlightType = 'default' | 'emerald';

const EmeraldHighlight = styled.span`
  ${tw`text-emerald-900 bg-emerald-100`}
`;

const highlight = (
  input: string,
  regexes: RegExp[],
  variant?: HighlightType
): ResultType => {
  variant = variant ? variant : 'default';

  if (!regexes.length) {
    if (!/\S/.test(input)) return [];
    return [input];
  }
  const splitText = input.split(regexes[0]);
  const replacements = input.match(regexes[0]);
  const result: ResultType = [];
  const len = splitText.length - 1;
  if (replacements)
    for (let i = 0; i < len; i++) {
      result.push(...highlight(splitText[i], regexes.slice(1), variant));

      const element =
        variant === 'emerald' ? (
          <EmeraldHighlight key={i}>{replacements[i]}</EmeraldHighlight>
        ) : (
          <strong key={i}>{replacements[i]}</strong>
        );

      result.push(element);
    }
  result.push(...highlight(splitText[len], regexes.slice(1), variant));
  return result;
};

export const multipleStringHighlights = (
  inputData: string,
  keywords: string[],
  variant?: HighlightType
): React.ReactNode => {
  variant = variant ? variant : 'default';
  const regexes = keywords
    .filter(Boolean)
    .filter((word) => !/^\+/.test(word))
    .map((word) => escapeRegExp(word))
    .map((word) => new RegExp(`\\b${word}\\b`, 'ig'));
  return highlight(inputData, regexes, variant);
};
