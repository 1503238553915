import * as React from 'react';

import { AttachmentCountButton } from '@/atoms/AttachmentCountButton';
import { PopperContainer } from '@/atoms/PopperContainer';
import { Popper } from '@/components/Popper/Popper';
import { useForkRef } from '@/hooks/useForkRef';
import { useModal } from '@/hooks/useModal';

type CountType = {
  children: React.ReactNode;
  handleOpenModal: (e: React.MouseEvent<HTMLSpanElement>) => void;
};

type ListOverflowType = {
  children: React.ReactNode;
  count: number;
};

const Count = React.forwardRef<HTMLButtonElement, CountType>(
  ({ handleOpenModal, children }, ref) => {
    if (!children) {
      return null;
    }

    return (
      <AttachmentCountButton onClick={handleOpenModal} ref={ref}>
        +{children}
      </AttachmentCountButton>
    );
  }
);

export const ListOverflow = React.forwardRef<
  HTMLButtonElement,
  ListOverflowType
>(({ children, count }, parentRef) => {
  const { showModal, openModal, closeModal } = useModal();

  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const forkedRef = useForkRef(parentRef, anchor);

  const handleOpenModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    openModal();
  };

  return (
    <>
      <Count handleOpenModal={handleOpenModal} ref={forkedRef}>
        {count}
      </Count>
      <Popper
        targetRef={anchor}
        isVisible={showModal}
        closeOnOutSideClick
        closePopper={closeModal}
      >
        <PopperContainer>{children}</PopperContainer>
      </Popper>
    </>
  );
});
