// @ts-strict-ignore
import { CustomCheckboxInput } from '@reach/checkbox';
import { useField } from 'formik';
import * as React from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

import {
  commonCheckboxContainerStyles,
  StyledCheckbox,
} from '@/atoms/Check/common';
import { useControlled } from '@/hooks/useControlled';

import CheckedIcon from './icons/check.svg?react';
import IntermediateIcon from './icons/checkbox-dash.svg?react';
import UnCheckedIcon from './icons/rectangle.svg?react';

export type CheckBoxType = Omit<
  React.HTMLAttributes<HTMLInputElement>,
  'css' | 'onChange'
> & {
  /**
   * Checkbox size
   */
  size?: 'large' | 'medium' | 'small';
  /**
   * checkbox selected
   */
  checked?: boolean;
  /**
   * intermediate
   */
  intermediate?: boolean;
  /**
   * default checked
   */
  defaultChecked?: boolean;
  /**
   * is disabled?
   */
  disabled?: boolean;
  /**
   * onChange handler
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * onBlur handler
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * onFocus handler
   */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * name
   */
  name?: string;
  /**
   * padding around the input
   */
  padding?: boolean;
  /**
   * HTML value attribute
   */
  value?: string;
};

export type FormikCheckBoxType = CheckBoxType & {
  name: string;
  value?: string;
};

type DisplayCheckboxType = Pick<
  CheckBoxType,
  'checked' | 'disabled' | 'intermediate' | 'padding' | 'size'
>;

type StyledCheckboxContainerProp = Pick<
  CheckBoxType,
  'disabled' | 'padding' | 'size'
>;

const commonCheckboxStyles = css<StyledCheckboxContainerProp>`
  ${({ size, padding }) =>
    size === 'small' && (padding ? tw`p-0.5 w-4.5 h-4.5` : tw`w-3.5 h-3.5`)}
  ${({ size, padding }) =>
    size === 'medium' && (padding ? tw`w-7 h-7` : tw`w-5 h-5`)}
  ${({ size, padding }) =>
    size === 'large' && (padding ? tw`w-8 h-8` : tw`w-6 h-6`)}
`;

const StyledCheckboxContainer = styled(
  StyledCheckbox
)<StyledCheckboxContainerProp>`
  &[data-reach-custom-checkbox-container] {
    ${commonCheckboxStyles}
  }
`;

const StyledDisplayCheckboxContainer = styled.span<StyledCheckboxContainerProp>`
  ${tw`cursor-pointer`}
  &[data-reach-custom-checkbox-container] {
    ${commonCheckboxContainerStyles}
    ${commonCheckboxStyles}
  }
`;

const StyledCheckboxInput = styled(CustomCheckboxInput)`
  outline: none;
`;

const StyledCheckedIcon = styled(CheckedIcon)`
  ${tw`fill-current text-white`}
`;

export const CheckBox = React.forwardRef<HTMLSpanElement, CheckBoxType>(
  (
    {
      checked,
      name,
      defaultChecked,
      intermediate,
      disabled = false,
      padding,
      size = 'medium',
      onChange = () => null,
      onBlur = () => null,
      onFocus = () => null,
      id,
      value: htmlValue,
      ...rest
    },
    ref
  ) => {
    const [value, setValue, isControlled] = useControlled<boolean>({
      controlled: checked,
      default: !!defaultChecked,
      name: 'Checkbox',
    });

    const checkedValue = isControlled ? checked : value;
    const changeHandler = intermediate
      ? onChange
      : isControlled
      ? onChange
      : (event: React.ChangeEvent<HTMLInputElement>) =>
          setValue(event.target.checked);

    const icon = intermediate ? (
      <IntermediateIcon />
    ) : checkedValue ? (
      <StyledCheckedIcon />
    ) : (
      <UnCheckedIcon />
    );

    return (
      <StyledCheckboxContainer
        ref={ref}
        defaultChecked={defaultChecked}
        checked={checkedValue}
        size={size}
        onChange={changeHandler}
        onBlur={onBlur}
        disabled={disabled}
        onFocus={onFocus}
        padding={padding}
        {...rest}
      >
        <StyledCheckboxInput id={id} name={name} value={htmlValue} />
        {icon}
      </StyledCheckboxContainer>
    );
  }
);

export const FormikCheckBox = React.forwardRef<
  HTMLLabelElement,
  FormikCheckBoxType
>(({ name, children, value, ...rest }, ref) => {
  const [field] = useField({ name, type: 'checkbox', value });
  return (
    <label ref={ref} className='flex items-center space-x-2 mb-4 w-max'>
      <CheckBox {...rest} {...field} value={value} name={name} />
      {children}
    </label>
  );
});

export const DisplayCheckBox = React.forwardRef<
  HTMLSpanElement,
  DisplayCheckboxType
>(({ checked, disabled, intermediate, padding, size }, ref) => {
  const icon = intermediate ? (
    <IntermediateIcon />
  ) : checked ? (
    <StyledCheckedIcon />
  ) : (
    <UnCheckedIcon />
  );

  return (
    <StyledDisplayCheckboxContainer
      ref={ref}
      data-reach-custom-checkbox-container
      disabled={disabled}
      size={size}
      padding={padding}
      data-state={checked ? 'checked' : 'unchecked'}
      role='checkbox'
      aria-checked={checked}
    >
      {icon}
    </StyledDisplayCheckboxContainer>
  );
});
