import { AnimateSharedLayout } from 'framer-motion';
import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { useOverlayType } from '@/components/AppOverlay';
import { AnimatedNavbar } from '@/components/Atoms/AnimatedNavbar';
import { AnimatedNavButton } from '@/components/Atoms/AnimatedNavLink';
import { ASK_NEEDL_NOT_ENABLED } from '@/features/ChatBotV2/constants';
import { useAskNeedlDispatchContext } from '@/features/ChatBotV2/context';
import { ASK_NEEDL_ACTIONS } from '@/features/ChatBotV2/hooks/useAskNeedlState';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';
import { useHeaderText } from '@/features/Feed/hooks/useHeaderText';
import { useConfigUI } from '@/hooks/query/useFeatureConfig';
import { useUserHasData } from '@/hooks/query/useUserHasData';
import { useLocationMatch } from '@/hooks/useLocationMatch';

const SearchWithinImpl = ({
  searchWithin,
  currentFeedOrDocumentId,
}: SearchWithinType) => {
  const dispatch = useAskNeedlDispatchContext();

  const { notebooksMatch, connectMatch, askNeedlPageMatch, feedsHomeMatch } =
    useLocationMatch();
  const urlLocation = useLocation();
  const urlParams = React.useMemo(
    () => new URLSearchParams(urlLocation.search),
    [urlLocation.search]
  );
  const { hasData, isLoading } = useUserHasData();
  const { isLoading: isConfigLoading, data: config } = useConfigUI();

  const disableMyData =
    (!!askNeedlPageMatch || !!feedsHomeMatch) && (isLoading || !hasData);

  const disableNeedlUniverse =
    isConfigLoading || !config?.ask_needl_dataset_selection_needl_universe;

  const disableSelection =
    !!notebooksMatch ||
    !!connectMatch ||
    disableMyData ||
    isConfigLoading ||
    !config?.ask_needl_dataset_selection_user_data;

  const {
    params: { category, source },
  } = useRouteMatch<{
    category: string;
    source: string;
  }>(['/:category/:source/:subCategory']) || {
    params: {},
  };

  const {
    params: { deeplink },
  } = useRouteMatch<{
    preview: string;
    previewSource: string;
    deeplink: string;
  }>(['/:preview/:previewSource/:deeplink']) || {
    params: {},
  };

  const overlayType = useOverlayType();

  const handleSelectionChat = React.useCallback(() => {
    const feedId =
      source === 'needl-universe' ? 'needl-universe' : source ? source : 'home';

    dispatch({
      type: ASK_NEEDL_ACTIONS.SET_CURRENT_FEED_OR_DOCUMENT_ID,
      payload: {
        searchWithin: 'feed',
        currentFeedOrDocumentId: feedId,
      },
    });
  }, [dispatch, source]);

  const handleNeedlUnierseChat = React.useCallback(() => {
    dispatch({
      type: ASK_NEEDL_ACTIONS.SET_CURRENT_FEED_OR_DOCUMENT_ID,
      payload: {
        searchWithin: 'feed',
        currentFeedOrDocumentId: 'needl-universe',
      },
    });
  }, [dispatch]);

  const handleDocumentChat = React.useCallback(() => {
    const documentId = urlParams.get('overlay-deeplink') ?? deeplink;
    if (!documentId) {
      return;
    }
    dispatch({
      type: ASK_NEEDL_ACTIONS.SET_CURRENT_FEED_OR_DOCUMENT_ID,
      payload: {
        searchWithin: 'document',
        currentFeedOrDocumentId: documentId as string,
      },
    });
  }, [dispatch, deeplink, urlParams]);

  React.useEffect(() => {
    if (
      (ASK_NEEDL_NOT_ENABLED.includes(source ?? '') || disableSelection) &&
      overlayType !== 'preview' &&
      category !== 'preview'
    ) {
      handleNeedlUnierseChat();
    } else if (overlayType === 'preview' || category === 'preview') {
      handleDocumentChat();
    } else {
      handleSelectionChat();
    }
  }, [
    handleNeedlUnierseChat,
    handleSelectionChat,
    handleDocumentChat,
    disableSelection,
    source,
    category,
    overlayType,
  ]);

  const data = useHeaderText();
  const location = askNeedlPageMatch ? 'My Data' : data?.location;

  const showSelectionButton =
    !ASK_NEEDL_NOT_ENABLED.includes(source ?? '') &&
    !disableSelection &&
    location;

  const showNeedlUniverseButton = !disableNeedlUniverse;

  const currentSelectedNav =
    searchWithin === 'feed' && currentFeedOrDocumentId === 'needl-universe'
      ? 'needl-universe'
      : 'selection';

  return overlayType === 'preview' || category === 'preview' ? null : (
    <div className='px-1'>
      <AnimateSharedLayout>
        <AnimatedNavbarWithBorder addDivider={false}>
          {showSelectionButton ? (
            <AnimatedNavButton
              onClick={handleSelectionChat}
              selected={currentSelectedNav === 'selection'}
              textDark={currentSelectedNav === 'selection'}
              variant={!askNeedlPageMatch ? 'gray' : 'default'}
            >
              {location}
            </AnimatedNavButton>
          ) : null}
          {showNeedlUniverseButton ? (
            <AnimatedNavButton
              onClick={handleNeedlUnierseChat}
              selected={currentSelectedNav === 'needl-universe'}
              textDark={currentSelectedNav === 'needl-universe'}
              variant={!askNeedlPageMatch ? 'gray' : 'default'}
            >
              Needl Universe
            </AnimatedNavButton>
          ) : null}
        </AnimatedNavbarWithBorder>
      </AnimateSharedLayout>
    </div>
  );
};

const AnimatedNavbarWithBorder = styled(AnimatedNavbar)`
  ${tw`border-b border-gray-300`}
`;

export const SearchWithin = withAskNeedlStateSlice<
  Record<string, unknown>,
  SearchWithinType
>(SearchWithinImpl, (props, state) => ({
  ...props,
  searchWithin: state.data.searchWithin,
  currentFeedOrDocumentId: state.data.currentFeedOrDocumentId,
}));

type SearchWithinType = {
  searchWithin: 'document' | 'feed';
  currentFeedOrDocumentId: string;
};
