import { useMutation } from 'react-query';

import { ML_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

import { useInvalidateGeneratedReport } from './useFetchGeneratedReport';
import { useInvalidateUserGeneratedReports } from './useFetchUserGeneratedReports';

export const usePutReportFeedback = () => {
  const invalidateAllGeneratedReports = useInvalidateUserGeneratedReports();
  const invalidateReport = useInvalidateGeneratedReport();

  return useMutation(
    ({ reportId, rating, comments }: MutationData) => {
      return axiosInstance.put(
        ML_API_ROUTES.reportGeneration.reportInfo(reportId),
        {
          rating,
          comments,
        }
      );
    },
    {
      onSuccess: async (_, { reportId }) => {
        await Promise.all([
          invalidateAllGeneratedReports(),
          invalidateReport(reportId),
        ]);
      },
    }
  );
};

type MutationData = {
  reportId: string;
  rating: number | null;
  comments: string | null;
};
