import Rating from '@mui/material/Rating';
import * as React from 'react';
import ReactHotToast from 'react-hot-toast';

import { Button } from '@/components/Button/Button';
import { TextArea } from '@/components/Input/TextArea';
import {
  useActiveReportId,
  useFetchGeneratedReport,
  usePutReportFeedback,
} from '@/features/ReportGeneration/hooks';

export const Feedback = () => {
  const activeReportId = useActiveReportId();
  const { data: report } = useFetchGeneratedReport(activeReportId);
  const updateMutation = usePutReportFeedback();

  const [rating, setRating] = React.useState<number | null>(
    report?.rating ?? null
  );
  const [comments, setComments] = React.useState<string>(
    report?.comments ?? ''
  );

  const handleSubmit = async () => {
    try {
      await updateMutation.mutateAsync({
        reportId: activeReportId,
        rating,
        comments,
      });
      ReactHotToast.success('Feedback submitted successfully');
    } catch (e) {
      ReactHotToast.error('Failed to submit feedback');
    }
  };

  if (report?.result?.error === 'true') {
    return null;
  }

  return (
    <div className='flex flex-col gap-4 rounded-lg bg-gray-200 p-4'>
      <span className='headline-small-bold self-center'>Feedback</span>
      <span className='body-sm-bold'>Rating</span>
      <Rating
        name='rating'
        size='large'
        value={rating}
        onChange={(_, newValue) => {
          setRating(newValue);
        }}
      />
      <TextArea
        className='h-32'
        placeholder='Type your feedback / comments here'
        label='Comments'
        name='comments'
        type='text'
        value={comments}
        onChange={(e) => {
          setComments(e.target.value);
        }}
      />
      <Button
        variant='primary'
        disabled={rating === report?.rating && comments === report?.comments}
        loading={updateMutation.isLoading}
        className='ml-auto'
        label='Submit'
        onClick={handleSubmit}
      />
    </div>
  );
};
