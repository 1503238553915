import styled from 'styled-components';
import tw from 'twin.macro';

type PopperContainerProps = {
  helpPopper?: boolean;
};

export const PopperContainer = styled.div<PopperContainerProps>`
  ${tw`p-4 rounded bg-white shadow-common-card flex flex-col space-y-2`}
  ${({ helpPopper }) => helpPopper && tw`bg-gray-900`}
`;
