import { makeStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import type * as React from 'react';
import type { FallbackProps } from 'react-error-boundary';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    placeContent: 'center',
    border: '1px solid #ff000040',
    background: theme.palette.type === 'light' ? '#ff00000f' : '#ff000030',
    padding: '16px',
    alignSelf: 'center',
    flex: 2,
  },
  container: {
    textAlign: 'center',
  },
}));

export const ErrorBoundary: React.ComponentType<FallbackProps> = ({
  error,
}) => {
  const classes = useStyle();

  if (error?.message?.match(/failed to fetch dynamically imported module/gi)) {
    window.location.reload();
  }

  return (
    <div role='alert' className={classes.root}>
      <div className={classes.container}>
        <div>
          <ErrorOutline />
        </div>
        <p>{error?.message}</p>
        <p>Something went wrong</p>
        <p>
          Please contact us at{' '}
          <a
            href='mailto:support@needl.ai'
            className='text-emerald-700 underline'
          >
            support@needl.ai
          </a>
        </p>
      </div>
    </div>
  );
};
