import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { SOURCE_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { isAppAllowed } from '@/components/ConnectApps/Common/appLibraryData';
import { timeInMs } from '@/constants/time';
import type { FeedNavDataType, UseFetchNavReturnType } from '@/types';

import { useConfigUI } from './useFeatureConfig';

const fetcher = async ({ signal }: QueryFunctionContext) => {
  const { data } = await axiosInstance.get<{ sources: FeedNavDataType[] }>(
    SOURCE_API_ROUTES.private(),
    { signal }
  );
  return data.sources || [];
};

export const NAVIGATION_PRIVATEAPPS = ['navigation', 'private-apps'];

export const useFetchPrivateApps = (): UseFetchNavReturnType => {
  const { isLoading: isConfigLoading, data: config } = useConfigUI();

  const {
    isLoading,
    isError,
    data: privateAppsData,
    ...queryData
  } = useQuery(NAVIGATION_PRIVATEAPPS, fetcher, {
    staleTime: timeInMs.FIFTEEN_SECONDS,
  });

  const filteredData = React.useMemo(() => {
    if (!privateAppsData) {
      return privateAppsData;
    }
    if (!config) {
      return [];
    }

    const filteredApps = privateAppsData.filter((app) =>
      isAppAllowed(app.key, config)
    );

    return filteredApps;
  }, [privateAppsData, config]);

  return {
    ...queryData,
    isLoading: isLoading || isConfigLoading,
    isError,
    data: filteredData,
  };
};

export const useInvalidatePrivateApps = (): (() => void) => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    queryClient.invalidateQueries(NAVIGATION_PRIVATEAPPS);
  }, [queryClient]);
};

export const usePrefetchPrivateApps = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(NAVIGATION_PRIVATEAPPS, {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};
