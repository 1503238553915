import { FileItem } from '@/features/ReportGeneration/components/UploadSection/SelectedFiles';
import {
  useActiveReportId,
  useFetchGeneratedReport,
} from '@/features/ReportGeneration/hooks';

export const FilesAdded = () => {
  const activeReportId = useActiveReportId();
  const { data: report } = useFetchGeneratedReport(activeReportId);

  return (
    <div className='h-96 flex flex-col gap-4 shadow-lg p-4'>
      <span className='headline-small-bold self-center'>Files Added</span>
      <ul className='flex flex-col gap-2 overflow-auto'>
        {report?.payload?.documents?.map((document) => (
          <a
            key={document.metadata.guid}
            href={document.object_location}
            target='_blank'
            rel='noreferrer noopener'
          >
            <FileItem fileName={document.metadata.fileName} />
          </a>
        ))}
      </ul>
    </div>
  );
};
