// @ts-strict-ignore
import * as React from 'react';
import type { SSEOptions, SSEvent } from 'sse.js';
import { SSE } from 'sse.js';

import { getIdToken } from '@/components/AuthProvider';

const EVENTS = {
  START: '[START]',
  ERROR: '[ERROR]',
};

const EVENTS_STATUS = {
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
};

const reducer = <T>(
  state: SSEStateType<T>,
  action: SSEActionType<T>
): SSEStateType<T> => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
        isStreaming: !action.payload,
        isError: false,
      };
    case 'SET_ERROR':
      return {
        ...state,
        isLoading: false,
        isStreaming: false,
        isError: true,
      };
    case 'EMIT_EVENT':
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    case 'SET_DATA':
      return {
        ...state,
        isLoading: false,
        isStreaming: false,
        data: action.payload,
      };
    case 'CLEAR_DATA':
      return {
        ...state,
        events: [],
        data: undefined,
        isLoading: false,
        isStreaming: false,
        isError: false,
      };
    default:
      throw new Error('Invalid action type for useSSERequest reducer');
  }
};

export const useSSERequest = <
  ResponseType,
  ContentEventType = ContentEvent<string>
>(
  rawEvents: boolean = true
) => {
  const [state, dispatch] = React.useReducer(reducer<ResponseType>, {
    isLoading: false,
    isStreaming: false,
    isError: false,
    events: [],
    data: undefined,
  });

  const isLoadingRef = React.useRef(state.isLoading);
  const setLoading = (loading: boolean) => {
    isLoadingRef.current = loading;
    dispatch({ type: 'SET_LOADING', payload: loading });
  };

  const makeSSERequest = React.useCallback(
    async (
      urlPath: string,
      options: SSEOptions,
      stopLoadingEventStatus: string,
      contentEventHandler: (event: ContentEventType) => void
    ) => {
      setLoading(true);

      const token = await getIdToken();
      const url = import.meta.env.VITE_API_URL + urlPath;

      const eventSource = new SSE(url, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers,
          token,
        },
      });

      eventSource.onmessage = (event) => {
        if (rawEvents) {
          dispatch({ type: 'EMIT_EVENT', payload: event });
        }

        if (event.data && event.data.startsWith(EVENTS.ERROR)) {
          dispatch({ type: 'SET_ERROR' });
        }

        if (event.data && !event.data.startsWith('[')) {
          const response = JSON.parse(event.data);

          if (
            response.status === stopLoadingEventStatus &&
            isLoadingRef.current
          ) {
            setLoading(false);
          }

          if (response.status === EVENTS_STATUS.DONE) {
            dispatch({
              type: 'SET_DATA',
              payload: response.data as ResponseType,
            });
          } else {
            contentEventHandler(response);
          }
        }
      };

      eventSource.onerror = (error) => {
        dispatch({ type: 'SET_ERROR' });
        console.error('SSE error:', error);
      };

      eventSource.onopen = () => {
        // eslint-disable-next-line no-console
        console.log('SSE connection established.');
      };

      return () => {
        if (eventSource) {
          eventSource.close();
          // eslint-disable-next-line no-console
          console.log('SSE connection closed.');
        }
      };
    },
    [rawEvents]
  );

  const clearData = React.useCallback(() => {
    dispatch({ type: 'CLEAR_DATA' });
  }, []);

  return {
    makeSSERequest,
    clearData,
    isLoading: state.isLoading,
    isStreaming: state.isStreaming,
    isError: state.isError,
    events: state.events,
    data: state.data,
  };
};

type SSEStateType<T> = {
  events: SSEvent[];
  data: T;
  isLoading: boolean;
  isStreaming: boolean;
  isError: boolean;
};

type SSEActionType<T> =
  | {
      type: 'CLEAR_DATA';
    }
  | {
      type: 'EMIT_EVENT';
      payload: SSEvent;
    }
  | {
      type: 'SET_DATA';
      payload: T;
    }
  | {
      type: 'SET_ERROR';
    }
  | {
      type: 'SET_LOADING';
      payload: boolean;
    };

export type ContentEvent<T> = {
  status: string;
  data: T;
};
