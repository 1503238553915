import * as React from 'react';

import type { CommonComponentType } from '@/types';

export type PhillColors =
  | 'default'
  | 'error'
  | 'info-gray'
  | 'info'
  | 'success'
  | 'warn';

export type PillType = CommonComponentType & {
  /**
   * Pill color
   */
  color?: PhillColors;
  children?: React.ReactNode;
  /**
   * choose a darker color
   */
  dark?: boolean;
  /**
   * is used as header?
   */
  header?: boolean;
  /**
   * is truncate?
   */
  truncate?: boolean;
  /**
   * size ?
   */
  size?: 'default' | 'small';
  onClick?: (e: React.MouseEvent) => void;
};

export const Pill = React.forwardRef<HTMLElement, PillType>(
  (
    {
      color = 'default',
      size = 'default',
      children,
      className,
      dark = false,
      header,
      style,
      truncate = true,
      onClick,
      ...rest
    },
    ref
  ) => {
    const Component = header ? 'h3' : 'span';
    const Truncate = truncate ? 'truncate' : '';
    const Size = size === 'default' ? 'body-sm' : 'body-xs';

    let ColorClass = dark ? 'bg-gray-300' : 'bg-gray-100';

    switch (color) {
      case 'success':
        ColorClass = dark ? 'bg-green-300' : 'bg-green-100';
        break;
      case 'info':
        ColorClass = dark
          ? 'bg-secondary-blue-300'
          : 'bg-blue-100 bg-opacity-10';
        break;
      case 'info-gray':
        ColorClass = 'bg-gray-300';
        break;
      case 'warn':
        ColorClass = dark
          ? 'bg-secondary-yellow-500'
          : 'bg-yellow-300 bg-opacity-10';
        break;
      case 'error':
        ColorClass = 'bg-red-100';
        break;
      default:
        break;
    }

    return (
      <Component
        // @ts-expect-error have to figure out inference later
        ref={ref}
        className={`${Truncate} w-min rounded-md px-2 py-1 text-gray-900 ${Size} ${ColorClass} ${className}`}
        style={style}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);
