import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { ButtonCount } from '@/atoms/StorageDashboard/ButtonCount/ButtonCount';
import { Text } from '@/atoms/Text/Text';
import { ExpandableInput } from '@/components/ExpandableInput';
import SearchIcon from '@/icons/search.svg?react';

export type TableHeaderType = {
  actions?: React.ReactNode;
  /**
   * count of selected items
   */
  selectedItemsCount?: number;
  /**
   * search value
   */
  searchValue?: string;
  /**
   * selected items click handler
   */
  selectedItemsClickHandler?: (e: React.MouseEvent) => void;
  /**
   * search input change handler
   */
  searchInputChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * filter click handler
   */
  filterClickHandler?: (e: React.MouseEvent) => void;
  /**
   * label of the table
   */
  tableLabel?: React.ReactNode;
};

const Container = styled.div`
  ${tw`flex w-full items-center py-1.5 px-3`}
`;

const StyledButtonCount = styled(ButtonCount)`
  ${tw`mx-2 my-2.5`}
`;

const ActionsContainer = styled.div`
  ${tw`flex items-center space-x-4 ml-auto`}
`;

const SearchBarContainer = styled.div`
  ${tw`justify-end`}
`;

export const TableHeader = ({
  actions,
  selectedItemsCount = 0,
  searchValue,
  selectedItemsClickHandler = () => null,
  searchInputChangeHandler,
  tableLabel = '',
}: TableHeaderType): JSX.Element => {
  return (
    <Container>
      {selectedItemsCount > 0 ? (
        <StyledButtonCount
          label='Selected'
          count={selectedItemsCount}
          onClick={selectedItemsClickHandler}
        />
      ) : (
        <Text className='line-clamp-1' bold>
          {tableLabel}
        </Text>
      )}
      <ActionsContainer>
        {searchInputChangeHandler ? (
          <SearchBarContainer>
            <ExpandableInput
              defaultExpanded={false}
              label='search'
              icon={<SearchIcon />}
              iconButtonLabel='search'
              placeholder='Search'
              value={searchValue}
              onChange={searchInputChangeHandler}
            />
          </SearchBarContainer>
        ) : null}
        {actions}
      </ActionsContainer>
    </Container>
  );
};
