import * as React from 'react';

export const useModal = () => {
  const [showModal, setIsShowing] = React.useState(false);

  const toggleModal = () => setIsShowing((state) => !state);

  const openModal = () => setIsShowing(true);

  const closeModal = () => setIsShowing(false);

  return {
    closeModal,
    openModal,
    showModal,
    toggleModal,
  };
};
