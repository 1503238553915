import ReactCopyToClipboard from 'react-copy-to-clipboard';
import ReactHotToast from 'react-hot-toast';

import { Tooltip } from '@/components/Atoms/Tooltip/Tooltip';
import { Button } from '@/components/Button/Button';
import { IconButton } from '@/components/IconButton/IconButton';
import {
  useActiveReportId,
  useFetchGeneratedReport,
} from '@/features/ReportGeneration/hooks';
import CopyIcon from '@/icons/copy.svg?react';
import { downloadJSONFile } from '@/utils/downloadJSONFile';

export const ReportFooter = () => {
  const activeReportId = useActiveReportId();
  const { data: report } = useFetchGeneratedReport(activeReportId);

  const handleCopy = () => {
    ReactHotToast.success('Copied to clipboard');
  };

  const handleDownload = () => {
    if (!report?.result) {
      ReactHotToast.error('No response available to download');
      return;
    }

    downloadJSONFile(`${report.result.title}_response.json`, {
      title: report.result.title,
      content: report.result.content,
      raw_data: report.result.raw_data,
    });
  };

  if (report?.result?.error === 'true') {
    return null;
  }

  return (
    <div className='flex items-center'>
      <Tooltip label='Copy Report'>
        <div>
          <ReactCopyToClipboard
            text={report?.result?.content ?? ''}
            onCopy={handleCopy}
          >
            <IconButton size='small'>
              <CopyIcon className='text-emerald-900' />
            </IconButton>
          </ReactCopyToClipboard>
        </div>
      </Tooltip>
      <Button
        size='small'
        variant='tertiary'
        onClick={handleDownload}
        label='Download Raw Data'
      />
    </div>
  );
};
